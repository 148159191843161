import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from '@mui/material';
import React, { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ZeiterfassungSelectDatum from './ZeiterfassungSelectDatum';
import ZeiterfassungSelectDatumErfassteZeiten from './ZeiterfassungSelectDatumErfassteZeiten';
import ZeiterfassungContextProvider from '../../../../Contexts/ZeiterfassungContext/ZeiterfassungContextProvider';
import ZeiterfassungContext from '../../../../Contexts/ZeiterfassungContext/ZeiterfassungContext';
import ZeiterfassungNeueZeitmeldung from './ZeiterfassungNeueZeitmeldung';

export default function Zeiterfassung() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ZeiterfassungContextProvider>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Zeiterfassung
        </Typography>
        <ZeiterfassungSelectDatum />
        <ZeiterfassungSelectDatumErfassteZeiten />

        {/* The ZeiterfassungNeueZeitmeldung content will be rendered inside a dialog */}
        <ZeiterfassungDialog />
      </Box>
    </ZeiterfassungContextProvider>
  );
}

// Separate component for the dialog functionality
function ZeiterfassungDialog() {
  const {
    createNeueZeitmeldung,
    setCreateNeueZeitmeldung,
    editMode,
    resetForm,
    setEditMode,
    setCurrentEditId,
  } = useContext(ZeiterfassungContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Handle dialog close
  const handleClose = () => {
    setCreateNeueZeitmeldung(false);
    resetForm();
    if (editMode) {
      setEditMode(false);
      setCurrentEditId(-1);
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="md"
      open={createNeueZeitmeldung}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          ...(fullScreen ? {} : { width: '100%', maxWidth: 700 }),
        },
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 1,
          position: 'absolute',
          right: 8,
          top: 8,
          zIndex: 1,
        }}>
        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ p: { xs: 2, sm: 3 } }}>
        <ZeiterfassungNeueZeitmeldung />
      </DialogContent>
    </Dialog>
  );

}
