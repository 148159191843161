import React, {ReactNode, useContext, useEffect, useRef} from 'react';

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ZeiterfassungContext, {IArbeitszeitenDay} from './ZeiterfassungContext';
import {
  ApiError,
  Arbeitszeiteintrag,
  ArbeitszeitService,
  BauvorhabenWithID,
} from '../../services/openapi';
import dayjs, {Dayjs} from 'dayjs';
import APIWrapperContext from '../../services/APIWrapperContext';
import UserContext from '../UserContext/UserContext';

interface IZeiterfassungContextProviderProps {
  children?: ReactNode;
}

interface Arbeitszeiteintrag_bvhID extends Omit<Arbeitszeiteintrag, 'bvh'> {
  bvh: number;
}

const ZeiterfassungContextProvider = ({children}: IZeiterfassungContextProviderProps) => {
  // Lift the value into the parent's state: https://reactjs.org/docs/context.html#caveats
  const {call_backend_api} = useContext(APIWrapperContext);
  const [selectedDate, setSelectedDate] = React.useState<Date>(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [arbeitszeitenDay, setArbeitszeitenDay] = React.useState<IArbeitszeitenDay[]>([]);
  const [bauvorhaben, setBauvorhaben] = React.useState('');
  const [bauvorhabenList, setBauvorhabenList] = React.useState<BauvorhabenWithID[]>([]);
  const [createNeueZeitmeldung, setCreateNeueZeitmeldung] = React.useState<boolean>(false);
  const [startZeit, setStartZeit] = React.useState<Dayjs | null>(null);
  const [endeZeit, setEndeZeit] = React.useState<Dayjs | null>(null);
  const [pauseZeit, setPauseZeit] = React.useState<Dayjs | null>(dayjs('1900-01-01T00:45'));
  const [taetigkeit, setTaetigkeit] = React.useState<string>('');
  const [isUrlaub, setIsUrlaub] = React.useState<boolean>(false);
  const [isKrank, setIsKrank] = React.useState<boolean>(false);
  const [isSchlechtwetter, setIsSchlechtwetter] = React.useState<boolean>(false);
  const {additionalUserdata} = useContext(UserContext);
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [currentEditId, setCurrentEditId] = React.useState<number>(-1);

  // Add refs to track previous status values
  const prevIsUrlaubRef = useRef<boolean>(isUrlaub);
  const prevIsKrankRef = useRef<boolean>(isKrank);
  const prevIsSchlechtwetterRef = useRef<boolean>(isSchlechtwetter);

  const abteilung = additionalUserdata?.zeiterfassung_abteilung;

  const loadArbeitszeitToEdit = (id: number) => {
    const arbeitszeitToEdit = arbeitszeitenDay.find((az) => az.id === id);

    if (arbeitszeitToEdit) {
      // Erst die Edit-Mode-Flags setzen
      setEditMode(true);
      setCurrentEditId(id);
      setCreateNeueZeitmeldung(true);

      // Status-Flags setzen, falls notwendig
      const isUrlaubEntry = arbeitszeitToEdit.taetigkeit === 'Urlaub';
      const isKrankEntry = arbeitszeitToEdit.taetigkeit === 'Krank';
      const isSchlechtwetterEntry = arbeitszeitToEdit.taetigkeit === 'Schlechtwetter';

      setIsUrlaub(isUrlaubEntry);
      setIsKrank(isKrankEntry);
      setIsSchlechtwetter(isSchlechtwetterEntry);

      // Bauvorhaben setzen
      setBauvorhaben(arbeitszeitToEdit.bauvorhaben);

      // Startzeit setzen mit Berücksichtigung der Zeitzone
      // Hier konvertieren wir die startzeit zu einem dayjs-Objekt OHNE Timezone-Verschiebung
      const startDate = dayjs(arbeitszeitToEdit.start);
      setStartZeit(startDate);

      // Endzeit setzen
      const endDate = dayjs(arbeitszeitToEdit.ende);
      setEndeZeit(endDate);

      // Pausenzeit setzen
      const pauseTime = dayjs('1900-01-01T00:00').add(arbeitszeitToEdit.pause, 'second');
      setPauseZeit(pauseTime);

      // Tätigkeit setzen
      setTaetigkeit(arbeitszeitToEdit.taetigkeit || '');
    }
  };

  const updateArbeitszeit = () => {
    let selectedBauvorhaben = bauvorhabenList.find((el) => el.bvh_name === bauvorhaben);

    const transformTime = (time: Dayjs | null, day: Date) => {
      if (time !== null) {
        const dateStr = day.toISOString().split('T')[0];
        const timeStr = time.format('HH:mm:ss');
        return `${dateStr}T${timeStr}`;
      } else {
        return '';
      }
    };

    if (
      selectedBauvorhaben !== undefined &&
      startZeit !== null &&
      endeZeit !== null &&
      pauseZeit !== null
    ) {
      let arbeitszeiteintrag: Arbeitszeiteintrag = {
        id: currentEditId,
        bvh: selectedBauvorhaben,
        datum:
          selectedDate.getFullYear() +
          '-' +
          (selectedDate.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          selectedDate.getDate().toString().padStart(2, '0'),
        beginn: startZeit ? transformTime(startZeit, selectedDate) : '',
        ende: endeZeit ? transformTime(endeZeit, selectedDate) : '',
        pause: pauseZeit ? pauseZeit.diff(dayjs('1900-01-01T00:00')) / 1000 : 0,
        dauer:
          endeZeit?.diff(startZeit) / 1000 - pauseZeit?.diff(dayjs('1900-01-01T00:00')) / 1000 || 0,
        bearbeiter: 'me',
        kurzinfo: 'info',
        notiz: 'note',
        taetigkeit: taetigkeit,
      };

      call_backend_api(
        ArbeitszeitService.updateWorktimeArbeitzeitArbeitszeiteintragPut(arbeitszeiteintrag),
        undefined,
        undefined,
        () => {
          updateArbeitzeitenListe(selectedDate);
          setEditMode(false);
          setCurrentEditId(-1);
        },
        true
      );
    }
  };

  const resetForm = () => {
    setBauvorhaben('');
    setStartZeit(null);
    setEndeZeit(null);
    setPauseZeit(dayjs('1900-01-01T00:45'));
    setTaetigkeit('');
    // Do not reset isUrlaub, isKrank, isSchlechtwetter as they're handled separately
  };

  // New method to reset form values but keep the status
  const resetFormValues = () => {
    setBauvorhaben('');
    setTaetigkeit('');
    setStartZeit(null);
    setEndeZeit(null);
    setPauseZeit(dayjs('1900-01-01T00:45'));
  };

  const parseDateFromString = (dateString: string) => {
    const tmpDate = new Date(dateString);
    tmpDate.setSeconds(0);
    tmpDate.setMilliseconds(0);
    return tmpDate;
  };

  const deleteArbeitszeit = (id: number) => {
    call_backend_api(
      ArbeitszeitService.deleteWorktimesOfDateArbeitzeitArbeitszeiteintragDelete(id),
      () => {
        setArbeitszeitenDay(arbeitszeitenDay.filter((arbeitszeit) => arbeitszeit.id !== id));
      },
      undefined,
      undefined,
      true
    );
  };

  const updateBauvorhabenList = () => {
    call_backend_api(
      ArbeitszeitService.getAlleBauvorhabenArbeitzeitBauvorhabenGet(),
      (response: any) => {
        const userDepartment = additionalUserdata?.zeiterfassung_abteilung;

        if (userDepartment) {
          const filteredResponse = response.filter((bvh: BauvorhabenWithID) =>
            bvh.bvh_name.includes(userDepartment)
          );
          setBauvorhabenList(filteredResponse);
        } else {
          setBauvorhabenList(response);
        }
      },
      undefined,
      undefined,
      true
    );
  };

  React.useEffect(() => {
    updateBauvorhabenList();
  }, []);

  const getBauvorhabenForAbteilung = (abteilung: string | undefined): string => {
    let bauvorhabenName = 'null';

    if (abteilung === 'BB') {
      const bbBauvorhaben = bauvorhabenList.find((bvh) => bvh.bvh_name.includes('Sonstiges, BB'));
      if (bbBauvorhaben) {
        bauvorhabenName = bbBauvorhaben.bvh_name;
      }
    } else if (abteilung === 'BT') {
      const btBauvorhaben = bauvorhabenList.find((bvh) => bvh.bvh_name.includes('Sonstiges, BT'));
      if (btBauvorhaben) {
        bauvorhabenName = btBauvorhaben.bvh_name;
      }
    } else if (abteilung === 'BC') {
      const bcBauvorhaben = bauvorhabenList.find((bvh) => bvh.bvh_name.includes('Sonstiges, BC'));
      if (bcBauvorhaben) {
        bauvorhabenName = bcBauvorhaben.bvh_name;
      }
    }
    // Für BC und andere Abteilungen: Standard-Sonstiges verwenden

    return bauvorhabenName;
  };

  // Modified useEffect to handle status changes correctly including Schlechtwetter
  useEffect(() => {
    // Check if user is switching from special status to normal work status
    const switchingFromSpecialToNormal =
      (prevIsUrlaubRef.current || prevIsKrankRef.current || prevIsSchlechtwetterRef.current) &&
      !isUrlaub &&
      !isKrank &&
      !isSchlechtwetter;

    // When switching from special status to normal, reset the form values
    if (switchingFromSpecialToNormal) {
      resetFormValues();
    }

    // Set taetigkeit based on current status
    if (isKrank) {
      setTaetigkeit('Krank');
    } else if (isUrlaub) {
      setTaetigkeit('Urlaub');
    } else if (isSchlechtwetter) {
      setTaetigkeit('Schlechtwetter');
    }

    // For special status entries, set predefined times
    if (isUrlaub || isKrank || isSchlechtwetter) {
      const today = dayjs();
      const beginTime = today.hour(8).minute(0).second(0);
      const endTime = today.hour(16).minute(30).second(0);
      const pauseTime = 0;
      setStartZeit(beginTime);
      setEndeZeit(endTime);
      setPauseZeit(dayjs('1900-01-01T00:00').add(pauseTime, 'minute'));
      setBauvorhaben(getBauvorhabenForAbteilung(abteilung));
    }

    // Update the previous status refs for the next render
    prevIsUrlaubRef.current = isUrlaub;
    prevIsKrankRef.current = isKrank;
    prevIsSchlechtwetterRef.current = isSchlechtwetter;
  }, [
    isKrank,
    isUrlaub,
    isSchlechtwetter,
    abteilung,
    bauvorhabenList,
    editMode,
    createNeueZeitmeldung,
  ]);

  const updateArbeitzeitenListe = (selectedDate: Date) => {
    let year = selectedDate.toLocaleString('default', {year: 'numeric'});
    let month = selectedDate.toLocaleString('default', {month: '2-digit'});
    let day = selectedDate.toLocaleString('default', {day: '2-digit'});

    call_backend_api(
      ArbeitszeitService.getWorktimesOfDateArbeitzeitArbeitszeiteintragDateGet(
        year + '-' + month + '-' + day
      ),
      (response: any) => {
        setArbeitszeitenDay(
          response
            .map((el: any) => {
              return {
                id: el.id,
                start: parseDateFromString(el.beginn),
                ende: parseDateFromString(el.ende),
                pause: el.pause,
                bauvorhaben: el.bvh.bvh_name,
                taetigkeit: el.taetigkeit,
              };
            })
            .sort(
              (a: IArbeitszeitenDay, b: IArbeitszeitenDay) => a.start.getTime() - b.start.getTime()
            )
        );
      },
      (error: ApiError) => {
        console.log(error);
      },
      undefined,
      false
    );
  };

  // const { token,setAuthed,setCheckingAuthed } = React.useContext(SecurityContext)
  React.useEffect(() => {
    // Get year, month, and day part from the date
    setArbeitszeitenDay([]);
    updateArbeitzeitenListe(selectedDate);
  }, [selectedDate]);

  const sendArbeitszeit = () => {
    let selectedBauvorhaben = bauvorhabenList.find((el) => el.bvh_name === bauvorhaben);

    const transformTime = (time: Dayjs | null, day: Date) => {
      if (time !== null) {
        const dateStr = day.toISOString().split('T')[0];
        const timeStr = time.format('HH:mm:ss');
        return `${dateStr}T${timeStr}`;
      } else {
        return '';
      }
    };

    if (
      selectedBauvorhaben !== undefined &&
      startZeit !== null &&
      endeZeit !== null &&
      pauseZeit !== null
    ) {
      let arbeitszeiteintrag: Arbeitszeiteintrag = {
        bvh: selectedBauvorhaben,
        datum:
          selectedDate.getFullYear() +
          '-' +
          (selectedDate.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          selectedDate.getDate().toString().padStart(2, '0'),
        beginn: startZeit ? transformTime(startZeit, selectedDate) : '', // startZeit?.format("HH:mm") || "",
        ende: endeZeit ? transformTime(endeZeit, selectedDate) : '', //endeZeit?.format("HH:mm") || "",
        pause: pauseZeit ? pauseZeit.diff(dayjs('1900-01-01T00:00')) / 1000 : 0,
        dauer:
          endeZeit?.diff(startZeit) / 1000 - pauseZeit?.diff(dayjs('1900-01-01T00:00')) / 1000 || 0,
        bearbeiter: 'me',
        kurzinfo: 'info',
        notiz: 'note',
        taetigkeit: taetigkeit,
      };

      call_backend_api(
        ArbeitszeitService.addWorktimeArbeitzeitArbeitszeiteintragPost(arbeitszeiteintrag),
        undefined,
        undefined,
        () => {
          updateArbeitzeitenListe(selectedDate);
        },
        true
      );
    }
  };
  const createNewBauvorhaben = (mandnr: number, projektnr: number, bvh_name: string) => {
    call_backend_api(
      ArbeitszeitService.createBauvorhabenArbeitzeitBauvorhabenPost({
        mandnr: mandnr,
        projektnr: projektnr,
        bvh_name: bvh_name,
      }),
      () => {
        updateBauvorhabenList();
        setBauvorhaben(bvh_name);
      },
      () => {},
      undefined,
      true
    );
  };

  return (
    <ZeiterfassungContext.Provider
      value={{
        selectedDate,
        setSelectedDate,
        arbeitszeitenDay,
        setArbeitszeitenDay,
        deleteArbeitszeit,
        bauvorhaben,
        setBauvorhaben,
        bauvorhabenList,
        createNeueZeitmeldung,
        setCreateNeueZeitmeldung,
        startZeit,
        setStartZeit,
        endeZeit,
        setEndeZeit,
        pauseZeit,
        setPauseZeit,
        resetForm,
        taetigkeit,
        setTaetigkeit,
        sendArbeitszeit,
        createNewBauvorhaben,
        isUrlaub,
        setIsUrlaub,
        isKrank,
        setIsKrank,
        isSchlechtwetter,
        setIsSchlechtwetter,
        editMode,
        setEditMode,
        currentEditId,
        setCurrentEditId,
        loadArbeitszeitToEdit,
        updateArbeitszeit,
      }}>
      {children}
    </ZeiterfassungContext.Provider>
  );
};

export default ZeiterfassungContextProvider;
