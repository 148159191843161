import * as React from 'react';
import {Box, Typography} from '@mui/material';
import {TimeField} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import ZeiterfassungContext from '../../../../Contexts/ZeiterfassungContext/ZeiterfassungContext';

interface IZeiterfassungSelectArbeitszeiten {}

export default function ZeiterfassungSelectArbeitszeiten(props: IZeiterfassungSelectArbeitszeiten) {
  const {startZeit, setStartZeit, endeZeit, setEndeZeit, pauseZeit, setPauseZeit, isUrlaub, isKrank, editMode} = React.useContext(ZeiterfassungContext);

  const isStatusSelected = isUrlaub || isKrank;

  // Berechnung der Arbeitszeit
  const calculateArbeitszeit = React.useMemo(() => {
    if (startZeit?.isValid() && endeZeit?.isValid() && pauseZeit?.isValid()) {
      const startMs = startZeit.valueOf();
      const endeMs = endeZeit.valueOf();
      const pauseMs = pauseZeit.diff(dayjs('1900-01-01T00:00'));

      if (startMs < endeMs && endeMs - startMs > pauseMs) {
        return dayjs('1900-01-01T00:00')
          .add(endeMs - startMs - pauseMs, 'milliseconds')
          .format('HH:mm');
      }
    }
    return null;
  }, [startZeit, endeZeit, pauseZeit]);

  // Fehlerprüfungen
  const hasStartEndError = startZeit?.isValid() && endeZeit?.isValid() && startZeit.isAfter(endeZeit);
  const hasPauseError = startZeit?.isValid() && endeZeit?.isValid() && pauseZeit?.isValid() && (endeZeit.diff(startZeit) - pauseZeit.diff(dayjs('1900-01-01T00:00')) < 0 || endeZeit.diff(startZeit) === 0);

  return (
    <Box sx={{width: '100%'}}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: 2,
          mt: 2,
          opacity: isStatusSelected ? 0.6 : 1,
          pointerEvents: isStatusSelected ? 'none' : 'auto',
        }}>
        <TimeField
          label='Von'
          format='HH:mm'
          value={startZeit}
          onChange={(date) => setStartZeit(date)}
          color={hasStartEndError ? 'error' : 'primary'}
          fullWidth
          referenceDate={dayjs('1900-01-01T07:00')}
          slotProps={{
            textField: {
              helperText: hasStartEndError ? 'Start muss vor Ende sein' : ' ',
              FormHelperTextProps: {sx: {color: 'error.main'}},
            },
          }}
        />

        <TimeField
          label='Bis'
          format='HH:mm'
          value={endeZeit}
          onChange={(date) => setEndeZeit(date)}
          color={hasStartEndError ? 'error' : 'primary'}
          fullWidth
          referenceDate={dayjs('1900-01-01T17:00')}
          slotProps={{
            textField: {
              helperText: hasStartEndError ? 'Ende muss nach Start sein' : ' ',
              FormHelperTextProps: {sx: {color: 'error.main'}},
            },
          }}
        />

        <TimeField
          label='Pause'
          format='HH:mm'
          value={pauseZeit}
          onChange={(date) => setPauseZeit(date)}
          color={hasPauseError ? 'error' : 'primary'}
          fullWidth
          referenceDate={dayjs('1900-01-01T00:45')}
          slotProps={{
            textField: {
              helperText: hasPauseError ? 'Pause kann nicht länger als Arbeitszeit sein' : ' ',
              FormHelperTextProps: {sx: {color: 'error.main'}},
            },
          }}
        />
      </Box>

      {calculateArbeitszeit && (
        <Box sx={{mb: 2, mt: 2, p: 2, bgcolor: 'action.hover', borderRadius: 1}}>
          <Typography variant='h6' color='primary.main'>
            Arbeitszeit: {calculateArbeitszeit}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
