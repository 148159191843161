import {Alert, Box, Button, Typography} from '@mui/material';
import React, {useContext, useState} from 'react';
import dayjs from 'dayjs';
import ZeiterfassungSelectBauvorhaben from './ZeiterfassungSelectBauvorhaben';
import ZeiterfassungSelectArbeitszeiten from './ZeiterfassungSelectArbeitszeiten';
import ZeiterfassungContext from '../../../../Contexts/ZeiterfassungContext/ZeiterfassungContext';
import ZeiterfassungNeuesBauvorhaben from './ZeiterfassungNeuesBauvorhaben';
import ZeiterfassungStatus from './ZeiterfassungStatus';
import ZeiterfassungTaetigkeit from './ZeiterfassungTaetigkeit';
import {Cancel, Save} from '@mui/icons-material';

export default function ZeiterfassungNeueZeitmeldung() {
  const {
    createNeueZeitmeldung,
    setCreateNeueZeitmeldung,
    resetForm,
    sendArbeitszeit,
    bauvorhaben,
    startZeit,
    endeZeit,
    pauseZeit,
    taetigkeit,
    editMode,
    setEditMode,
    updateArbeitszeit,
    setCurrentEditId,
    isUrlaub,
    isKrank,
    isSchlechtwetter,
  } = useContext(ZeiterfassungContext);

  const [validationError, setValidationError] = useState<string | null>(null);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState<boolean>(false);

  // Validation function - only returns validation result, doesn't set error message
  const validateForm = () => {
    // Check if bauvorhaben is selected
    if (!bauvorhaben || bauvorhaben.length === 0 || bauvorhaben === 'null') {
      return {
        isValid: false,
        errorMessage: 'Bitte wählen Sie ein Bauvorhaben aus',
      };
    }

    // Check if times are valid
    if (!startZeit || !endeZeit || !pauseZeit) {
      return {
        isValid: false,
        errorMessage: 'Start- und Endzeit müssen angegeben werden',
      };
    }

    if (bauvorhaben.toLowerCase().includes('sonstiges') && !taetigkeit) {
      return {
        isValid: false,
        errorMessage: 'Bitte füllen Sie die Tätigkeit / Info aus',
      };
    }

    // Ensure start time is before end time
    if (!startZeit.isBefore(endeZeit)) {
      return {
        isValid: false,
        errorMessage: 'Die Startzeit muss vor der Endzeit liegen',
      };
    }

    // Ensure pause is not longer than total work time
    if (pauseZeit.diff(dayjs('1900-01-01T00:00')) >= endeZeit.diff(startZeit)) {
      return {
        isValid: false,
        errorMessage: 'Die Pausenzeit darf nicht länger als die Arbeitszeit sein',
      };
    }

    return {
      isValid: true,
      errorMessage: null,
    };
  };

  const handleClick_Abbrechen = () => {
    resetForm();
    setCreateNeueZeitmeldung(false);
    setHasAttemptedSubmit(false);
    if (editMode) {
      setEditMode(false);
      setCurrentEditId(-1);
    }
  };

  const handleClick_Save = () => {
    // Set flag that user has attempted to submit
    setHasAttemptedSubmit(true);

    // Validate the form
    const validation = validateForm();
    setValidationError(validation.errorMessage);

    // If not valid, don't proceed
    if (!validation.isValid) {
      return;
    }

    // Form is valid, proceed with save/update
    if (editMode) {
      updateArbeitszeit();
    } else {
      sendArbeitszeit();
    }

    // Reset states
    resetForm();
    setCreateNeueZeitmeldung(false);
    setHasAttemptedSubmit(false);
    if (editMode) {
      setEditMode(false);
      setCurrentEditId(-1);
    }
  };

  // Determine entry type for styling
  const getEntryTypeColor = () => {
    if (isUrlaub) return '#1976d2';
    if (isKrank) return '#d32f2f';
    if (isSchlechtwetter) return '#ed6c02';
    return '#2e7d32';
  };

  const getEntryType = () => {
    if (isUrlaub) return 'Urlaub';
    if (isKrank) return 'Krank';
    if (isSchlechtwetter) return 'Schlechtwetter';
    return 'Arbeitszeit';
  };

  if (!createNeueZeitmeldung) {
    return null;
  }

  // Get current validation result but only show error if user has attempted to submit
  const currentValidation = validateForm();
  const showValidationError = hasAttemptedSubmit && !currentValidation.isValid;

  return (
    <Box sx={{width: '100%', overflow: 'hidden'}}>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        {/* Header with title */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 3,
            pb: 2,
            borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
          }}>
          <Box>
            <Typography variant='h6' sx={{fontWeight: 'bold'}}>
              {editMode ? 'Zeiteintrag bearbeiten' : 'Neue Zeitmeldung erstellen'}
            </Typography>
            <Box
              sx={{
                mt: 0.5,
                bgcolor: getEntryTypeColor() + '15',
                color: getEntryTypeColor(),
                fontWeight: 'medium',
                px: 1,
                py: 0.3,
                display: 'inline-block',
              }}>
              {getEntryType()}
            </Box>
          </Box>
        </Box>

        {/* Error message display - only show after submission attempt */}
        {showValidationError && (
          <Alert
            severity='error'
            sx={{
              mb: 2,
            }}>
            {currentValidation.errorMessage}
          </Alert>
        )}

        {/* Form components */}
        <Box sx={{mb: 3}}>
          <Typography variant='subtitle2' sx={{mb: 1, color: 'text.secondary'}}>
            Status
          </Typography>
          <ZeiterfassungStatus />
        </Box>

        <Box sx={{mb: 3}}>
          <Typography
            variant='subtitle2'
            sx={{
              mb: 1,
              color: hasAttemptedSubmit && !bauvorhaben ? 'error.main' : 'text.secondary',
              display: 'flex',
              alignItems: 'center',
            }}>
            Bauvorhaben
            <Typography component='span' variant='caption' color='error' sx={{ml: 1}}>
              (Erforderlich)
            </Typography>
          </Typography>
          <ZeiterfassungSelectBauvorhaben />
          {!editMode && <ZeiterfassungNeuesBauvorhaben />}
        </Box>

        <Box sx={{mb: 3}}>
          <Typography
            variant='subtitle2'
            sx={{
              mb: 1,
              color:
                hasAttemptedSubmit && (!startZeit || !endeZeit) ? 'error.main' : 'text.secondary',
            }}>
            Zeiten
          </Typography>
          <ZeiterfassungSelectArbeitszeiten />
        </Box>

        <Box sx={{mb: 3}}>
          <Typography variant='subtitle2' sx={{mb: 1, color: 'text.secondary'}}>
            Tätigkeit
          </Typography>
          <ZeiterfassungTaetigkeit />
        </Box>

        {/* Action buttons */}
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mt: 4,
            pt: 3,
            borderTop: '1px solid rgba(0, 0, 0, 0.08)',
          }}>
          <Button
            variant='outlined'
            color='primary'
            onClick={handleClick_Abbrechen}
            fullWidth
            sx={{
              py: 1.2,
            }}
            startIcon={<Cancel />}>
            Abbrechen
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleClick_Save}
            fullWidth
            sx={{
              py: 1.2,
              fontWeight: 'medium',
              boxShadow: 2,
            }}
            startIcon={<Save />}>
            {editMode ? 'Speichern' : 'Hinzufügen'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
