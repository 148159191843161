import * as React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  ButtonGroup,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import ZeiterfassungContext from '../../../../Contexts/ZeiterfassungContext/ZeiterfassungContext';
import {
  SickOutlined,
  BeachAccessOutlined,
  WorkOutlineOutlined,
  ThunderstormOutlined,
} from '@mui/icons-material';

interface IZeiterfassungStatusDropdownProps {
  // Zusätzliche Props können hier definiert werden
}

// Definiere die möglichen Status-Optionen als Enum oder Konstanten
export enum StatusType {
  NONE = '',
  URLAUB = 'urlaub',
  KRANK = 'krank',
  SCHLECHTWETTER = 'schlechtwetter',
}

export default function ZeiterfassungStatus(props: IZeiterfassungStatusDropdownProps) {
  const {} = props;
  const theme = useTheme();

  // Add media queries for responsive layout
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmall = useMediaQuery('(max-width:400px)');

  const {isUrlaub, setIsUrlaub, isKrank, setIsKrank, isSchlechtwetter, setIsSchlechtwetter} =
    React.useContext(ZeiterfassungContext);

  // Bestimme den aktuellen Status basierend auf den Boolean-Werten
  const getCurrentStatus = (): StatusType => {
    if (isUrlaub) return StatusType.URLAUB;
    if (isKrank) return StatusType.KRANK;
    if (isSchlechtwetter) return StatusType.SCHLECHTWETTER;
    return StatusType.NONE;
  };

  const [status, setStatus] = React.useState<StatusType>(getCurrentStatus());

  // Aktualisiere den State wenn sich die Context-Werte ändern
  React.useEffect(() => {
    setStatus(getCurrentStatus());
  }, [isUrlaub, isKrank, isSchlechtwetter]);

  const handleStatusChange = (event: SelectChangeEvent<StatusType>) => {
    const newStatus = event.target.value as StatusType;
    setStatus(newStatus);

    // Setze alle Status auf false
    setIsUrlaub(false);
    setIsKrank(false);
    setIsSchlechtwetter(false);

    // Setze den ausgewählten Status auf true
    switch (newStatus) {
      case StatusType.URLAUB:
        setIsUrlaub(true);
        break;
      case StatusType.KRANK:
        setIsKrank(true);
        break;
      case StatusType.SCHLECHTWETTER:
        setIsSchlechtwetter(true);
        break;
      default:
        // Keinen Status setzen (leer)
        break;
    }
  };

  // Button-based selection handler
  const handleButtonSelect = (newStatus: StatusType) => {
    // If clicking the already selected status, deselect it
    if (status === newStatus) {
      setStatus(StatusType.NONE);
      setIsUrlaub(false);
      setIsKrank(false);
      setIsSchlechtwetter(false);
    } else {
      setStatus(newStatus);
      setIsUrlaub(newStatus === StatusType.URLAUB);
      setIsKrank(newStatus === StatusType.KRANK);
      setIsSchlechtwetter(newStatus === StatusType.SCHLECHTWETTER);
    }
  };

  // Define status options with icons, colors, and labels
  const statusOptions = [
    {
      value: StatusType.NONE,
      label: 'Arbeitszeit',
      icon: <WorkOutlineOutlined />,
      color: theme.palette.success.main,
    },
    {
      value: StatusType.URLAUB,
      label: 'Urlaub',
      icon: <BeachAccessOutlined />,
      color: theme.palette.primary.main,
    },
    {
      value: StatusType.KRANK,
      label: 'Krank',
      icon: <SickOutlined />,
      color: theme.palette.error.main,
    },
    {
      value: StatusType.SCHLECHTWETTER,
      label: 'Schlechtwetter',
      icon: <ThunderstormOutlined />,
      color: theme.palette.warning.main,
    },
  ];

  return (
    <Box sx={{width: '100%'}}>
      {/* Responsive Button Group Selection Interface */}
      <Stack spacing={2} direction='column' sx={{width: '100%'}}>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 1 : 2}
          sx={{width: '100%'}}>
          {statusOptions.map((option) => (
            <Button
              key={option.value}
              variant={status === option.value ? 'contained' : 'outlined'}
              onClick={() => handleButtonSelect(option.value)}
              startIcon={option.icon}
              sx={{
                flex: 1,
                py: 1.5,
                px: isExtraSmall ? 1 : 2,
                borderWidth: 1,
                backgroundColor: status === option.value ? `${option.color}` : 'transparent',
                borderColor: option.color,
                color: status === option.value ? 'white' : option.color,
                '&:hover': {
                  backgroundColor: status === option.value ? option.color : `${option.color}15`,
                  borderColor: option.color,
                },
                // Make text adjust on very small screens
                '& .MuiButton-startIcon': {
                  mr: isExtraSmall ? 0.5 : 1,
                },
                typography: isExtraSmall ? 'caption' : 'button',
                whiteSpace: 'nowrap',
              }}>
              {isExtraSmall && option.value === StatusType.SCHLECHTWETTER
                ? 'Schlecht.'
                : option.label}
            </Button>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}
