import React from 'react';
import {Chip} from '@mui/material';
import { StatusChipProps } from '../../../../services/openapi/models/Urlaubsantrag';

// Helper function to get status chip color
const getStatusColor = (status: string) => {
  switch (status) {
    case 'Beantragt':
      return 'warning';
    case 'Genehmigt':
      return 'success';
    case 'Abgelehnt':
      return 'error';
    default:
      return 'default';
  }
};

export const StatusChip: React.FC<StatusChipProps> = ({status}) => {
  return <Chip label={status} color={getStatusColor(status) as any} size='small' />;
};
