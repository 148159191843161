import * as React from 'react';
import {Alert, Box, Button, Dialog, DialogActions, DialogContent} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ContextProviderViewFileList from '../../../../ViewFileList/Contexts/ContectProviderViewFileList';
import {FileListDetails} from '../../../../ViewFileList/FileListDetails';
import {DndContext, DragEndEvent, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {DocumentsService} from "../../../../services/openapi";
import {call_backend_api} from "../../../../services/APIWrapper";
import ContextViewFileList from "../../../../ViewFileList/Contexts/ContextViewFileList";
import Snackbar from "@mui/material/Snackbar";


interface ITabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: ITabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{}}>
                    {/* <Typography> */}
                    {children}
                    {/* </Typography> */}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function Dokumentenverwaltung() {
    const [moveDialog, setMoveDialog] = React.useState(false);
    const [activeFolder, setActiveFolder] = React.useState<any>({});
    const [moveToFolder, setMoveToFolder] = React.useState<any>({});
    const [success, setSuccess] = React.useState<boolean>(false);
    const [showAlert, setShowAlert] = React.useState<boolean>(false);
    let theme = useTheme();
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    };
    const {
        dir_id_history
    } = React.useContext(ContextViewFileList);

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
        setValue(newValue);
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        })
    )


    function handleDragEnd(event: DragEndEvent) {
        if (event.collisions) {
            const moveTo = event.collisions.find(c => c.id !== event.active.id);
            if (moveTo) {
                setActiveFolder(event.active!.data!.current!);
                setMoveToFolder(moveTo.data!.droppableContainer.data.current);
                setMoveDialog(true);
            }
        }
    }

    function moveFolder() {
        call_backend_api(
            DocumentsService.moveFolderDocMoveFolderPost(moveToFolder.parent, moveToFolder.hidrive_id, activeFolder.hidrive_id),
            (res: any) => {
                setShowAlert(true)
                if (res.status >= 300) {
                    setSuccess(false)
                }
                if (res.status <= 299 || !res.status) {
                    setSuccess(false)
                }

            },
            undefined,
            () => {
                setMoveDialog(false)
            }
        );
    }

    return (
        <>
            <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
                <Container component="main">
                    <Typography sx={{typography: {xs: 'h4', md: 'h3'}, wordBreak: 'break-all'}}>Dokumentenverwaltung</Typography>
                    <Box sx={{width: '100%'}}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Dokumentenverwaltung" {...a11yProps(0)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Box sx={{width: '100%', marginTop: 5}}>
                                <ContextProviderViewFileList key={"not_user_specific"} user_specific={false}>
                                    <FileListDetails/>
                                </ContextProviderViewFileList>
                            </Box>
                        </TabPanel>
                    </Box>
                </Container>
                <Dialog open={moveDialog} onClose={() => setMoveDialog(false)}>
                    <DialogContent>
                        Ordern "{activeFolder.parent}" nach "{moveToFolder.parent}" verschieben?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setMoveDialog(false)}>Abbrechen</Button>
                        <Button onClick={() => moveFolder()}>OK</Button>
                    </DialogActions>
                </Dialog>
            </DndContext>
            <Snackbar open={showAlert} autoHideDuration={2500} onClose={handleClose}>
                <Alert severity={success ? 'success' : 'error'} sx={{width: '100%'}} onClose={handleClose}>
                    {success ? 'Ordner verschoben' : 'Fehler beim verschieben'}
                </Alert>
            </Snackbar>
        </>
    );
}





