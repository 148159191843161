import * as React from "react";
import {Box, CircularProgress, Divider, FormControl, IconButton, InputBase, InputLabel, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Select, SelectChangeEvent,} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PersonIcon from "@mui/icons-material/Person";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import GroupIcon from "@mui/icons-material/Group";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import DisAgreeDialogRaw from "./DialogWindowDisAgree";
import UserContext from "../../../../Contexts/UserContext/UserContext";
import UserManageGroupsDialogs from "./NutzergruppenProNutzer";
import Update from "@mui/icons-material/Update";

import {UserService} from "../../../../services/openapi";
// import APIWrapperContext from '../../../services/APIWrapperContext';
// import { OpenAPI } from '../../../services/openapi';
import APIWrapperContext from "../../../../services/APIWrapperContext";

const usergroups = ["Normaler Benutzer", "Admin", "Hausverwaltung"];

interface Iuser {
    email: string;
    userclass: string[];
    disabled: boolean;
}

interface Iusergroup {
    name: string;
    description: string;
    users: string[];
    disabled: boolean;
}

export default function NutzerListe() {
    const {call_backend_api} = React.useContext(APIWrapperContext);

    const {email} = React.useContext(UserContext);

    let theme = useTheme();

    const [userList, setUserList] = React.useState<Iuser[]>([]);
    const [tmp_userList, setTmp_UserList] = React.useState<Iuser[]>([]);
    const [userGroups, setUserGroups] = React.useState<string[]>([]);
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [openDialog_DisAggree_disable, setOpenDialog_DisAggree_disable] =
        React.useState(false);
    const [openDialog_DisAggree_delete, setOpenDialog_DisAggree_delete] =
        React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState<Iuser | undefined>(
        undefined
    );
    const [showLoading, setShowLoading] = React.useState(false);

    const handleClickMenu_ChangeUserGroup = () => {
        handleMobileMenuClose();
        setOpenDialog(true);
    };
    const handleClickMenu_disable = () => {
        handleMobileMenuClose();
        setOpenDialog_DisAggree_disable(true);
    };
    const handleClickMenu_deleteUser = () => {
        handleMobileMenuClose();
        setOpenDialog_DisAggree_delete(true);
    };

    const handleCloseDialog = (
        newValue?: string[] | boolean | undefined,
        action?: string | undefined
    ) => {

        setOpenDialog(false);
        setOpenDialog_DisAggree_delete(false);
        setOpenDialog_DisAggree_disable(false);
        if (
            typeof newValue != "boolean" &&
            newValue &&
            action === "changeUserGroup"
        ) {

            let old_usersGroups = selectedUser ? selectedUser.userclass : [];
            let new_usersGroups = newValue;
            let addToUser = [];
            let removeFromUser = [];
            addToUser = new_usersGroups.filter(
                (u: string) => !old_usersGroups.includes(u)
            );
            removeFromUser = old_usersGroups.filter(
                (u) => !new_usersGroups.includes(u)
            );

            addToUser.forEach((u: string) => {
                if (selectedUser) {
                    call_backend_api(
                        UserService.addUserToUsergroupUserAddToUsergroupPost(
                            selectedUser.email,
                            u
                        ),
                        undefined,
                        undefined,
                        () => {
                            loadUsers();
                        },
                        true,
                        "Nutzergruppe hinzugefügt",
                        "Fehler beim Hinzufügen."
                    );
                }
            });
            removeFromUser.forEach((u) => {
                if (selectedUser) {
                    call_backend_api(
                        UserService.removeUserFromUsergroupUserRemoveFromUsergroupDelete(
                            selectedUser.email,
                            u
                        ),
                        undefined,
                        undefined,
                        () => {
                            loadUsers();
                        },
                        true,
                        "Nutzergruppe entfernt",
                        "Fehler beim Entfernen."
                    );
                }
            });

            // updateUserGroup(token,selectedUser.email,newValue)
        }
        if (action === "disableUser") {
            if (selectedUser) {
                // Todo: Call Deactivate User => im Backend zur Usergruppe Deaktiviert hinzufügen
                //call_backend_api()
                if (selectedUser.userclass.includes("Deaktiviert")) {
                    call_backend_api(
                        UserService.removeUserFromUsergroupUserRemoveFromUsergroupDelete(
                            selectedUser.email,
                            "Deaktiviert"
                        ),
                        undefined,
                        undefined,
                        () => {
                            loadUsers();
                        },
                        true,
                        "Nutzer entsperrt",
                        "Fehler beim Entsperren des Benutzers."
                    );
                } else {
                    call_backend_api(
                        UserService.addUserToUsergroupUserAddToUsergroupPost(
                            selectedUser.email,
                            "Deaktiviert"
                        ),
                        undefined,
                        undefined,
                        () => {
                            loadUsers();
                        },
                        true,
                        "Nutzer Deaktiviert",
                        "Fehler beim Deaktivieren."
                    );
                }
            }
            // if(selectedUser)
            // {
            //     call_backend_api(UserService.updateUserByNameUserUpdateGet(selectedUser.email,"disabled", String(!selectedUser.disabled)))
            //     //updateUserDisabled(token, selectedUser.email, !selectedUser.disabled)
            // }
        }
        if (action === "deleteUser") {

            call_backend_api(
                UserService.deleteUserByNameUserDeleteDelete(
                    selectedUser ? selectedUser.email : ""
                ),
                () => {
                },
                (err: string) => {
                    console.log(err);
                },
                () => {
                    loadUsers();
                },
                true,
                "Nutzer gelöscht",
                "Fehler beim Löschen."
            );
            // if(selectedUser){
            //     call_backend_api(UserService.deleteUserByNameUserDeleteDelete(selectedUser.email))
            //     // deleteUser(token, selectedUser.email)
            // }
        }
        setSelectedUser(undefined);
    };

    const loadUsers = () => {
        setShowLoading(true);
        call_backend_api(
            UserService.listUsersUserListGet(),
            (t: Iuser[]) => setUserList(t),
            undefined,
            undefined,
            false,
            undefined,
            undefined
        );
        //getUsers(token).then(t => setUserList(t));
        call_backend_api(
            UserService.listUserGroupsUserGroupsGet(),
            (t: any[]) => setUserGroups(t.map((el) => el.classname)),
            undefined,
            undefined,
            false,
            undefined,
            undefined
        );
        //getUserGroups(false).then(t => setUserGroups(t));
    };

    React.useEffect(() => {
        loadUsers();
    }, []);


    React.useEffect(() => {
        if (userGroups.length > 0 && userList.length > 0) {
            setShowLoading(false);
        }

    }, [userGroups, userList]);
    React.useEffect(() => {
        if (selectedUser === undefined) {
            call_backend_api(
                UserService.listUsersUserListGet(),
                (t: Iuser[]) => setUserList(t),
                undefined,
                undefined,
                false,
                undefined,
                undefined
            );

        } else {

        }
        //getUserGroups(token).then(t=>setUserGroups(t));
    }, [selectedUser]);

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        React.useState<HTMLElement | null>(null);

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        handleMobileMenuClose();
    };
    const handleMenuCloseAndLogout = () => {
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (
        event: React.MouseEvent<HTMLElement>,
        user: Iuser | undefined
    ) => {
        setMobileMoreAnchorEl(event.currentTarget);
        setSelectedUser(user);
    };

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleClickMenu_ChangeUserGroup}>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                    {/* <Badge badgeContent={10} color="secondary"> */}
                    <GroupIcon/>
                    {/* </Badge> */}
                </IconButton>
                <p>Nutzergruppe ändern</p>
            </MenuItem>
            <MenuItem onClick={handleClickMenu_disable}>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    {/* <Badge badgeContent={17} color="secondary"> */}
                    <DoDisturbIcon/>
                    {/* </Badge> */}
                </IconButton>
                <p>De-/Aktivieren</p>
            </MenuItem>
            <MenuItem onClick={handleClickMenu_deleteUser}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <DeleteForeverIcon/>
                </IconButton>
                <p>Löschen</p>
            </MenuItem>
        </Menu>
    );
    const [filter_username, setFilter_username] = React.useState("");

    const handle_ChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter_username(event.target.value)

    };
    const [filter_usergroup, setFilter_usergroup] = React.useState("");

    const filter_users = React.useCallback(
        (userList: Iuser[]) => {
            let local_tmp_userList = userList;
            if (filter_username.length > 0) {
                local_tmp_userList = local_tmp_userList.filter((el) => el.email.includes(filter_username))
            }
            if (filter_usergroup.length > 0 && filter_usergroup !== "Alle Nutzergruppen") {
                local_tmp_userList = local_tmp_userList.filter((el) => el.userclass.includes(filter_usergroup))
            }

            return local_tmp_userList
        }
        , [filter_username, filter_usergroup])
    React.useEffect(() => {
        setTmp_UserList(filter_users(userList))

    }, [filter_username, filter_usergroup, userList, filter_users]);


    const handleChange = (event: SelectChangeEvent) => {
        setFilter_usergroup(event.target.value);

        // setTmp_UserList(userList);


        // setTmp_UserList(
        //   userList.filter((el) => el.userclass.includes(event.target.value) )
        // );

    };

    return (
        <>
            <Box sx={{display: "flex", flexDirection: "row"}}>
                <Box sx={{}}>
                    <FormControl variant="standard" sx={{m: 1, minWidth: 200}}>
                        <InputLabel id="demo-simple-select-standard-label">
                            Nutzergruppe
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={filter_usergroup.length == 0 ? "Alle Nutzergruppen" : filter_usergroup}
                            onChange={handleChange}
                            label="Nutzergruppe"
                        >
                            <MenuItem value="Alle Nutzergruppen">
                                <em>Alle Nutzergruppen</em>
                            </MenuItem>
                            {userGroups.map((el) => {
                                return <MenuItem value={el}>{el}</MenuItem>;
                            })}
                            {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{flex: 1, display: "flex", flexDirection: "column"}}>
                    <Box sx={{flex: 1}}></Box>
                    <Box>
                        <InputBase
                            onChange={handle_ChangeFilter}
                            sx={{ml: 1, flex: 1}}
                            placeholder="Filter..."
                            inputProps={{"aria-label": "filter users"}}
                        />
                        <IconButton type="button" sx={{p: "10px"}} aria-label="search" disabled>
                            <FilterAltIcon/>
                        </IconButton>
                    </Box>
                </Box>

                <Box sx={{flex: 1}}></Box>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Box sx={{flex: 1}}></Box>
                    <Box>
                        <IconButton
                            sx={{}}
                            onClick={() => {
                                loadUsers();
                            }}
                        >
                            <Update
                                onClick={() => {
                                    loadUsers();
                                }}
                            ></Update>
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            {showLoading && (
                <Box
                    sx={{
                        flexGrow: 1,
                        //position: 'relative',
                        margin: "10px auto",
                        //marginRight: theme.spacing(2),

                        width: "100px",
                    }}
                >
                    <CircularProgress color="primary" sx={{}}/>
                </Box>
            )}
            {!showLoading && (
                <List>
                    {tmp_userList.map((el) => {
                        return (
                            <>
                                <ListItem //selected={key == selectedIndex? true:false}

                                    secondaryAction={
                                        email !== el.email && (
                                            <IconButton
                                                color="primary"
                                                edge="end"
                                                aria-label="download"
                                                onClick={(e) => {
                                                    handleMobileMenuOpen(e, el);
                                                }}
                                            >
                                                <MoreVertIcon/>
                                            </IconButton>
                                        )
                                    }
                                >
                                    <ListItemIcon>
                                        <PersonIcon/>
                                    </ListItemIcon>

                                    <ListItemText
                                        sx={{color: "gray"}}
                                        primary={
                                            (el.userclass.includes("Deaktiviert")
                                                ? "Deaktiviert - "
                                                : "") + el.email
                                        }
                                        secondary={el.userclass
                                            .sort((a: string, b: string) =>
                                                a == "Deaktiviert"
                                                    ? 0
                                                    : b == "Deaktiviert"
                                                        ? 1
                                                        : a >= b
                                                            ? 1
                                                            : 0
                                            )
                                            .join(", ")}
                                    />
                                </ListItem>
                                <Divider/>
                            </>
                        );
                    })}
                </List>
            )}
            {!showLoading && tmp_userList.length == 0 && (
                <Box sx={{textAlign: "center", color: "gray"}}>
                    Keine Nutzer gefunden.
                </Box>
            )}
            {/* <ConfirmationDialog></ConfirmationDialog> */}
            <UserManageGroupsDialogs
                // keepMounted
                groupList={
                    selectedUser
                        ? userGroups.filter((u) => !selectedUser.userclass.includes(u))
                        : userGroups
                }
                usersGroups={selectedUser ? selectedUser.userclass : []}
                title={
                    "Nutzergruppen Definition für " +
                    (selectedUser ? selectedUser.email : "")
                }
                open={openDialog}
                closeFunction={handleCloseDialog}
            ></UserManageGroupsDialogs>
            {/* <ConfirmationDialogRaw
              id="nutzergruppe_change"
              keepMounted
              open={openDialog}
              onClose={handleCloseDialog}
              value={"test"}
              options={userGroups}
              title={"Nutzergruppe ändern"}
            ></ConfirmationDialogRaw> */}
            <DisAgreeDialogRaw
                id="user_disable"
                open={openDialog_DisAggree_disable}
                onClose={handleCloseDialog}
                action={"disableUser"}
                init_value={
                    selectedUser && selectedUser.disabled ? !selectedUser.disabled : false
                }
                text={
                    "Soll der Benutzer wirklich " +
                    (selectedUser && selectedUser.userclass.includes("Deaktiviert") ? "entsperrt" : "gesperrt") +
                    " werden?"
                }
                title={"Nutzer (ent-)sperren"}
            />
            <DisAgreeDialogRaw
                id="user_delete"
                open={openDialog_DisAggree_delete}
                onClose={handleCloseDialog}
                action={"deleteUser"}
                text={"Soll der Benutzer wirklich gelöscht werden?"}
                title={"Nutzer löschen"}
            />
            {renderMobileMenu}
        </>
    );
}
