import React from "react";
import {Avatar, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import {ArrowForwardIos, Folder} from "@mui/icons-material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {restore_slashes} from "../background_functions";
import ContextProviderElementFunctions from "../Contexts/ContectProviderElementFunctions";
import {ShowPDF} from "../ElementFunctions/ShowPDF/ShowPDF";
import {DownloadFileFunction} from "../ElementFunctions/DownloadFunctions/DownloadFileFunction";
import {EditSharedUsersAndUsergroups} from "../ElementFunctions/EditSharedUsersAndUsergroups/EditSharedUsersAndUsergroups";
import {UploadFile} from "../ElementFunctions/UploadFile/UploadFile";
import {AddFolder} from "../ElementFunctions/AddFolder/AddFolder";
import {downloadFile} from "../ElementFunctions/DownloadFunctions/background_tasks";
import {UserAvatarList} from "./UserAvatarList";
import {useDraggable, useDroppable} from '@dnd-kit/core';

interface FileListItemProps {
    element: any;
    accessibleElements: Record<string, any>;
    userSpecific: boolean | undefined;
    onDirectoryClick: (id: string) => void;
    setLoading: (loading: boolean) => void;
}

export const FileListItem: React.FC<FileListItemProps> = ({
                                                              element: el,
                                                              accessibleElements,
                                                              userSpecific,
                                                              onDirectoryClick,
                                                              setLoading
                                                          }) => {
    const handleItemClick = () => {
        setLoading(true);
        if (el.type === "dir") {
            onDirectoryClick(el.hidrive_id);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } else if (el.type === 'system') {
        } else {
            downloadFile(el.hidrive_id, el.parent);
            setLoading(false);
        }
    };

    const {
        attributes,
        listeners,
        setNodeRef: setDraggableNodeRef,
        transform
    } = useDraggable({
        id: el.hidrive_id,
        data: el,
        disabled: true
    });

    const {setNodeRef: setDroppableNodeRef, isOver} = useDroppable({
        id: el.hidrive_id,
        data: el
    });

    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: 9999,
        opacity: 0.7
    } : undefined;

    const combinedRef = (element: HTMLLIElement) => {
        setDroppableNodeRef(element);
        setDraggableNodeRef(element);
    };

    return (
        <ListItem
            id={el.hidrive_id}
            sx={{
                ":hover": {backgroundColor: "#f0f0f0", cursor: "pointer"},
            }}
            onClick={handleItemClick}
            ref={combinedRef} style={style} {...listeners} {...attributes}
        >
            <ListItemAvatar>
                <Avatar>
                    {el.type === "file" ? (
                        <InsertDriveFileIcon color="primary"/>
                    ) : (
                        <Folder/>
                    )}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={restore_slashes(el.parent)} secondary=""/>

            <ListItemAvatar>
                <UserAvatarList
                    accessibleElements={accessibleElements}
                    elementId={el.hidrive_id}
                />
            </ListItemAvatar>
            {el.type !== 'system' && (
                <ContextProviderElementFunctions
                    key={"ContextProviderElementFunctions_" + el.hidrive_id}
                    curr_dir_id={el.hidrive_id}
                    type={el.type}
                    name={el.parent}
                >
                    <ShowPDF/>
                    {el.type !== "dir" ? <DownloadFileFunction/> : <></>}
                    {el.type === "dir" ? <ArrowForwardIos/> : <></>}
                    {(userSpecific === undefined || userSpecific === false) && (
                        <>
                            <EditSharedUsersAndUsergroups/>
                            <UploadFile/>
                            <AddFolder/>
                        </>
                    )}
                </ContextProviderElementFunctions>
            )}
        </ListItem>
    );
};
