/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {Body_preconfigureUser_user_preconfigureUser_post} from '../models/Body_preconfigureUser_user_preconfigureUser_post';
import type {NewRegistration} from '../models/NewRegistration';
import type {newUser_return} from '../models/newUser_return';

import type {CancelablePromise} from '../core/CancelablePromise';
import {OpenAPI} from '../core/OpenAPI';
import {request as __request} from '../core/request';

export class UserService {
  /**
   * Get current User information
   * @returns newUser_return Successful Response
   * @throws ApiError
   */
  public static readUsersMeUserMeGet(): CancelablePromise<newUser_return> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user/me/',
    });
  }

  /**
   * Change Password for current User
   * @param oldPassword
   * @param newPassword
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static changeUsersPasswordUserMeChangePwPost(oldPassword: string, newPassword: string): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user/me/changePW',
      query: {
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Change Email for current User
   * @param newEmail
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static changeUsersEmailUserMeChangeEmailPost(newEmail: string): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user/me/changeEmail',
      query: {
        newEmail: newEmail,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get all Usergroups - Admins only
   * @returns any Successful Response
   * @throws ApiError
   */
  public static listUserGroupsUserGroupsGet(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user/groups',
    });
  }

  /**
   * List of all Users - Admins only
   * @param showPreConfiguredUsers
   * @returns newUser_return Successful Response
   * @throws ApiError
   */
  public static listUsersUserListGet(showPreConfiguredUsers: boolean = false): CancelablePromise<Array<newUser_return>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user/list',
      query: {
        showPreConfiguredUsers: showPreConfiguredUsers,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List of all Users with Metadata - Admins only
   * @param onlyKunden
   * @param showPreConfiguredUsers
   * @returns any Successful Response
   * @throws ApiError
   */
  public static listUsersWithMetadataUserListWithMetadataGet(onlyKunden: boolean = false, showPreConfiguredUsers: boolean = false): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user/listWithMetadata',
      query: {
        onlyKunden: onlyKunden,
        showPreConfiguredUsers: showPreConfiguredUsers,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create new User - Admins only
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static createUserUserCreatePost(requestBody: NewRegistration): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user/create',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Preconfigure User - Admins only - out of order at the moment
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static preconfigureUserUserPreconfigureUserPost(requestBody: Body_preconfigureUser_user_preconfigureUser_post): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user/preconfigureUser',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add new Usergroup - Admins only
   * @param groupname
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static addNewUsergroupUserAddNewUsergroupPost(groupname: string): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user/addNewUsergroup',
      query: {
        groupname: groupname,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add User to Usergroup - Admins only
   * @param username
   * @param usergroup
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static addUserToUsergroupUserAddToUsergroupPost(username: string, usergroup: string): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user/addToUsergroup',
      query: {
        username: username,
        usergroup: usergroup,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Remove User from Usergroup - Admins only
   * @param username
   * @param usergroup
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static removeUserFromUsergroupUserRemoveFromUsergroupDelete(username: string, usergroup: string): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/user/removeFromUsergroup',
      query: {
        username: username,
        usergroup: usergroup,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete User - Admins only
   * @param email
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteUserByNameUserDeleteDelete(email: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/user/delete',
      query: {
        email: email,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Reset Notifications
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static resetNotificationsUserResetNotificationsPost(): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user/resetNotifications',
    });
  }

  /**
   * Add Additional User Data - Admins only
   * @param userId
   * @param key
   * @param value
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static addAdditionalUserDataUserAddAdditionalUserDataPost(userId: string, key: string, value: string): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user/addAdditionalUserData',
      query: {
        user_id: userId,
        key: key,
        value: value,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List all Users with Additional Data
   * @returns any Successful Response
   * @throws ApiError
   */
  public static addAdditionalUserDataListUserAddAdditionalUserDataListGet(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user/addAdditionalUserDataList',
    });
  }

  /**
   * Get Additional Data for current User
   * @returns any Successful Response
   * @throws ApiError
   */
  public static meAdditionalUserDataUserMeAdditionalUserDataGet(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user/me/AdditionalUserData',
    });
  }

/**
   * Get vacation days for the current user
   * @returns VacationDaysResponse
   */
  public static getUserVacationDays(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user/get_vacation_days',
    });
  }

  /**
   * Get all vacation days for all users
   * @returns array of user vacation days
   */
  public static getAllVacationDays(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user/get_all_vacation_days',
    });
  }

  /**
   * Set vacation days for a user
   * @param user_id User ID
   * @param total_days Total vacation days
   * @param remaining_days Remaining vacation days
   * @param pending_days Pending vacation days
   * @returns 
   */
  public static setVacationDaysForUser(
    user_id: string, 
    total_days: number, 
    remaining_days: number, 
    pending_days: number = 0
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user/set_vacation_days',
      query: {
        user_id: user_id,
        total_days: total_days,
        remaining_days: remaining_days,
        pending_days: pending_days,
      },
    });
  }

  public static requestVacation(
    days_requested: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user/me/request_vacation',
      query: {
        days_requested: days_requested,
      },
    });
  }
}
