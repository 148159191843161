import * as React from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, ListItem, Paper, Typography} from "@mui/material";
import {ArbeitszeitService, BauvorhabenWithID} from "../../../../../services/openapi";
import APIWrapperContext from "../../../../../services/APIWrapperContext";
import ZeiterfassungContextProvider from "../../../../../Contexts/ZeiterfassungContext/ZeiterfassungContextProvider";
import List from "@mui/material/List";
import DeleteIcon from "@mui/icons-material/Delete";


interface IZeiterfassungSelectBauvorhabenProps {

}

export default function BauvorhabenList(props: IZeiterfassungSelectBauvorhabenProps) {
    const {} = props;
    const {call_backend_api} = React.useContext(APIWrapperContext);
    const [bauvorhabenList, setBauvorhabenList] = React.useState<BauvorhabenWithID[]>([]);
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [name, setName] = React.useState('');
    const [id, setId] = React.useState(0);


    const loadBauvorhaben = () => {
        call_backend_api(
            ArbeitszeitService.getAlleBauvorhabenArbeitzeitBauvorhabenGet(),
            (t: BauvorhabenWithID[]) => {
                setBauvorhabenList(t)
            },
            undefined,
            undefined,
            false,
            undefined,
            undefined
        );
    };

    React.useEffect(() => {
        loadBauvorhaben();
    }, []);

    const handleClose = () => {
        setName('')
        setOpen(false);
        setError(false);
    }

    const handleDelete = (confirm = false) => {
        call_backend_api(
            ArbeitszeitService.deleteBauvorhaben(id, confirm),
            () => {
                setBauvorhabenList([...bauvorhabenList.filter(b => b.id !== id)])
                setOpen(false);
                setId(0)
                setName('')
            },
            (e: any) => {
                if (e.status === 409) {
                    setError(true)
                }
            },
            undefined,
            false,
            undefined,
            undefined
        );
    }


    return (
        <>
            <Paper sx={{m: 1, p: 1}}>
                <ZeiterfassungContextProvider>
                    <Box sx={{display: "flex", flexDirection: "column"}}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Alle Bauvorhaben
                        </Typography>
                        <List>
                            {bauvorhabenList.map((el) => {
                                return (
                                    <>
                                        <ListItem key={el.id}
                                                  secondaryAction={
                                                      <IconButton
                                                          color="error"
                                                          edge="end"
                                                          aria-label="download"
                                                          onClick={(e) => {
                                                              setName(el.bvh_name)
                                                              setId(el.id)
                                                              setOpen(true)
                                                          }}
                                                      >
                                                          <DeleteIcon/>
                                                      </IconButton>
                                                  }>
                                            {el.bvh_name} - Mandant: {el.mandnr}, Projektnummer: {el.projektnr}
                                        </ListItem>
                                        <Divider/>
                                    </>
                                );
                            })}
                        </List>
                    </Box>
                </ZeiterfassungContextProvider>
            </Paper>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{}}
            >
                <DialogTitle>Bauvorhaben löschen?</DialogTitle>
                <DialogContent>
                    {error ? (
                        <div style={{marginBottom: '16px'}}>
                            <Typography color="red" style={{'paddingBottom': '1rem', fontWeight: 'bold'}}>
                                Beim Löschen ist ein Fehler aufgetreten. es ist für das Bauvorhaben bereits eine Zeiterfassung vorhanden.
                                Solltest du das Bauvorhaben dennoch löschen wollen, wird bei allen vorhandenen Einträgen das Bauvorhaben entfernt.
                                Achtung!
                            </Typography>
                            <Button onClick={() => handleDelete(true)} variant={'contained'}>Ganz sicher Löschen!</Button>
                        </div>
                    ) : <></>}
                    Bauvorhaben "{name}" wirklich dauerhaft löschen. Dieser Schritt kann nicht rückgängig gemacht werden.
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined'>Abbrechen</Button>
                    <Button onClick={() => handleDelete()} variant={'contained'}>Löschen</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
