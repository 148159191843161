import {Box, Button, Typography} from '@mui/material';
import React, {useContext} from 'react';
import {OpenAPI} from '../../../../../services/openapi';
import dayjs from 'dayjs';
import ZeiterfassungDashboardContext from '../../../../../Contexts/ZeiterfassungDashboardContext/ZeiterfassungDashboardContext';

export default function ExportWorkingData() {
  const {selectedDateStart, selectedDateEnde, filter, arbeitszeiten_alle_MA} = useContext(
    ZeiterfassungDashboardContext
  );

  // Format dates for URL parameters and filenames
  const formatDateForUrl = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Helper function to build the query parameters
  const buildQueryParams = () => {
    const params = new URLSearchParams();

    params.append('timeInterval_start', formatDateForUrl(selectedDateStart));
    params.append('timeInterval_end', formatDateForUrl(selectedDateEnde));

    // Add optional filter params
    if ('department' in filter && filter['department']) {
      params.append('filter_department', filter['department']);
    } else {
      params.append('filter_department', '');
    }

    if ('user' in filter && filter['user']) {
      params.append('filter_user', filter['user']);
    } else {
      params.append('filter_user', '');
    }

    return params.toString();
  };

  // Get user name from user ID for the filename
  const getUserName = () => {
    if (
      !('user' in filter) ||
      !filter['user'] ||
      !arbeitszeiten_alle_MA ||
      !('user_zeiterfassung' in arbeitszeiten_alle_MA)
    ) {
      return '';
    }

    const userInfo = arbeitszeiten_alle_MA.user_zeiterfassung.find(
      (user: any) => user.user_id === filter['user']
    );

    if (!userInfo) {
      return '';
    }

    // Use full name if available, otherwise use email username
    return userInfo.fullname && userInfo.fullname !== ''
      ? userInfo.fullname.replace(/\s+/g, '_')
      : userInfo.email.split('@')[0];
  };

  // Generate appropriate filename for the export
  const generateFilename = () => {
    const startDateFormatted = dayjs(selectedDateStart).format('YYYY-MM-DD');
    const endDateFormatted = dayjs(selectedDateEnde).format('YYYY-MM-DD');
    let filename = `worktime_export_${startDateFormatted}_to_${endDateFormatted}`;

    // Add department if available
    if ('department' in filter && filter['department']) {
      filename += `_${filter['department']}`;
    }

    // Add user info if available
    const userName = getUserName();
    if (userName) {
      filename += `_${userName}`;
    } else if ('user' in filter && filter['user']) {
      filename += `_${filter['user']}`;
    }

    return `${filename}.xlsx`;
  };

  const handleExport = async () => {
    try {
      const url = `${OpenAPI.BASE}/arbeitzeit/dashboard/export/worktimes?${buildQueryParams()}`;

      const response = await fetch(url, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Create download link
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');

      downloadLink.style.display = 'none';
      downloadLink.href = downloadUrl;
      downloadLink.download = generateFilename();

      // Trigger download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error('Export failed:', error);
      // You could add proper error handling here, e.g. showing a snackbar
    }
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'row', m: 2}}>
      <Typography variant='h5'>Export</Typography>
      <Box sx={{flex: 1}} />
      <Button onClick={handleExport} variant='contained'>
        Export
      </Button>
    </Box>
  );
}
