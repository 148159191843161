import React from 'react';
import {TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Box, Typography, IconButton, CircularProgress} from '@mui/material';
import {Check as CheckIcon, Close as CloseIcon} from '@mui/icons-material';
import dayjs from 'dayjs';
import { OffeneAntraegeTabProps } from '../../../../services/openapi/models/Urlaubsantrag';

export const OffeneAntraegeTab: React.FC<OffeneAntraegeTabProps> = ({loading, antraege, onProcess}) => {
  // Format date for display
  const formatDate = (dateString: string) => {
    return dayjs(dateString).format('DD.MM.YYYY');
  };

  if (loading) {
    return (
      <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}>
        <CircularProgress />
      </Box>
    );
  }

  if (antraege.length === 0) {
    return (
      <Box sx={{p: 2}}>
        <Typography variant='body1'>Keine offenen Urlaubsanträge vorhanden</Typography>
      </Box>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Mitarbeiter</TableCell>
            <TableCell>Von</TableCell>
            <TableCell>Bis</TableCell>
            <TableCell>Grund</TableCell>
            <TableCell>Eingereicht am</TableCell>
            <TableCell>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {antraege.map((antrag) => (
            <TableRow key={antrag.id}>
              <TableCell>{antrag.username}</TableCell>
              <TableCell>{formatDate(antrag.beginn)}</TableCell>
              <TableCell>{formatDate(antrag.ende)}</TableCell>
              <TableCell>{antrag.grund || '-'}</TableCell>
              <TableCell>{formatDate(antrag.antrag_datum)}</TableCell>
              <TableCell>
                <IconButton size='small' color='success' onClick={() => onProcess(antrag.id, 'Genehmigt')} title='Genehmigen'>
                  <CheckIcon />
                </IconButton>
                <IconButton size='small' color='error' onClick={() => onProcess(antrag.id, 'Abgelehnt')} title='Ablehnen'>
                  <CloseIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
