import * as React from 'react';
import { useState,useCallback,useEffect } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import HomeIcon from '@mui/icons-material/Home';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import AnalyticsIcon from '@mui/icons-material/Analytics';

import PersonIcon from '@mui/icons-material/Person';

import GroupsIcon from '@mui/icons-material/Groups';
import DrawerLeft from './Drawer/Drawer';
import AppBar from './AppBar/AppBar';
import * as serviceWorker from '../serviceWorkerRegistration';
import CloseIcon from '@mui/icons-material/Close';


import MainPages from './MainPages/MainPages';
import { Button, Icon, IconButton, Snackbar } from '@mui/material';
import ServiceWorkerUI from './ServiceWorkerUI';
import { Aod, Discount, FolderDelete, LineAxis, SettingsSystemDaydream, TapAndPlay, TimeToLeave } from '@mui/icons-material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
const navigationLinks_Standard = [
  {
    name: "Startseite",
    link: "/",
    icon: <HomeIcon />,
    authNeeded: false
  },
  // {
  //   name: "Meine Dokumente",
  //   link: "/documents",
  //   icon: <InboxIcon />,
  //   authNeeded: false
  // },
]
const navigationLinks_Admin = [

  {
    name: "Usermanagement",
    link: "/usermanagement",
    icon: <PeopleAltIcon />,
    authNeeded: true
  },
  {
    name: "Nutzergruppen",
    link: "/usergroupmanagement",
    icon: <GroupsIcon />,
    authNeeded: true
  },
  {
    name: "Dokumentenverwaltung",
    link: "/document_management",
    icon: <InboxIcon />,
    authNeeded: true
  },
  {
    name: "Hausverwaltung",
    link: "/hausverwaltung",
    icon: <HolidayVillageIcon />,
    authNeeded: true
  },
  {
    name: "Speicherplatz",
    link: "/cloud_storage",
    icon: <SettingsSystemDaydream />,
    authNeeded: true
  },
  {
    name: "Pflege der Werbung",
    link: "/configure_ads",
    icon: <Discount />,
    authNeeded: true
  },
  {
    name: "Pflege Datenschutzerklärung",
    link: "/privacy-policy-edit",
    icon: <AdminPanelSettingsIcon />,
    authNeeded: true
  },

]

const navigationLinks_Userspecific = [
  {
    name: "FAQs",
    link: "/faqs",
    icon: <HelpCenterIcon />,
    authNeeded: false
  },
  {
    name: "Mein Account",
    link: "/user_options",
    icon: <PersonIcon />,
    authNeeded: true
  },
]

const navigationLinks_Special = [
  {
    name: "Logs",
    link: "/logs",
    icon: <AnalyticsIcon />,
    authNeeded: true
  },
  
]

const navigationLinks_DeleteFolder = [
  {
    name: "Ordner Löschen",
    link: "/deleteFolder",
    icon: <FolderDelete />,
    authNeeded: true
  },
]
const navigationLinks_Zeiterfassung = [
  {
    name: "Zeiterfassung - Übersicht",
    link: "/zeiterfassung/dashboard",
    icon: <LineAxis />,
    authNeeded: true
  },
  {
    name: "mobile Zeiterfassung",
    link: "/zeiterfassung",
    icon: <Aod />,
    authNeeded: true
  },
  {
    name: "Stempeln",
    link: "/zeiterfassung/stempeln",
    icon: <TapAndPlay />,
    authNeeded: true
  }, 
/*  {
    name: "Urlaubsantrag", 
    link: "/zeiterfassung/urlaub",
    icon: <TimeToLeave />,
    authNeeded: true
  }*/
]


interface IApplicationProps {
    children?: React.ReactNode;
}

export default function Application(props:IApplicationProps) {
  

  return (
    // <ThemeProvider theme={theme.palette.main}>
    <>
      <ServiceWorkerUI>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar></AppBar>
        <DrawerLeft
          navigationLinks_Admin={navigationLinks_Admin}
          navigationLinks_Standard={navigationLinks_Standard}
          navigationLinks_Userspecific={navigationLinks_Userspecific}
          navigationLinks_Special={navigationLinks_Special}
          navigationLinks_DeleteFolder={navigationLinks_DeleteFolder}
          navigationLinks_Zeiterfassung={navigationLinks_Zeiterfassung}
        ></DrawerLeft>
        <MainPages></MainPages>

          {props.children}
        
      </Box>
      </ServiceWorkerUI>
    </>
    // </ThemeProvider>
  );
}
