import React from "react";
import { Avatar, Stack } from "@mui/material";
import { stringAvatar } from "../background_functions";

interface UserAvatarListProps {
  accessibleElements: Record<string, any>;
  elementId: string;
}

export const UserAvatarList: React.FC<UserAvatarListProps> = ({ 
  accessibleElements, 
  elementId 
}) => {
  // If no access information is available, return null
  if (!(elementId in accessibleElements)) return null;
  
  const element = accessibleElements[elementId];
  
  return (
    <Stack direction="row" spacing={1}>
      {element && "users" in element &&
        (element["users"] as any).map((user: string) => (
          <Avatar key={`user-${user}`} {...stringAvatar(user)} title={user} />
        ))}
        
      {element && "usergroups" in element &&
        (element["usergroups"] as any).map((group: string) => (
          <Avatar key={`group-${group}`} {...stringAvatar(group)} title={group} />
        ))}
    </Stack>
  );
};