import {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import {SnackbarState, UrlaubsantragWithID, VacationDaysResponse, FormData, Urlaubsantrag} from '../../services/openapi/models/Urlaubsantrag';
import {UrlaubsantragService} from '../../services/openapi/services/UrlaubsantragService';
import {UserService} from '../../services/openapi';

export const useUrlaubsantrag = (isApprover: boolean) => {
  // State
  const [loading, setLoading] = useState(false);
  const [meineAntraege, setMeineAntraege] = useState<UrlaubsantragWithID[]>([]);
  const [pendingAntraege, setPendingAntraege] = useState<UrlaubsantragWithID[]>([]);
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: '',
    severity: 'success',
  });
  const [formData, setFormData] = useState<FormData>({
    startDate: null,
    endDate: null,
    grund: '',
    notiz: '',
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [businessDays, setBusinessDays] = useState(0);
  const [vacationDaysInfo, setVacationDaysInfo] = useState<VacationDaysResponse | null>(null);
  const [remainingVacationDays, setRemainingVacationDays] = useState<number | null>(null);
  const [pendingVacationDays, setPendingVacationDays] = useState<number | null>(null);
  const [totalVacationDays, setTotalVacationDays] = useState<number | null>(null);

  // Hilfsfunktion für Fehlerbehandlung
  const handleError = (message: string, error: any) => {
    console.error(message, error);
    setSnackbar({
      open: true,
      message: message,
      severity: 'error',
    });
  };

  // Hilfsfunktion für Erfolgsmeldungen
  const showSuccess = (message: string) => {
    setSnackbar({
      open: true,
      message: message,
      severity: 'success',
    });
  };

  // Calculate business days between two dates
  const calculateVacationDays = async (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    try {
      const response = await UrlaubsantragService.calculateVacationDays(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
      return response;
    } catch (error) {
      handleError('Fehler bei der Berechnung der Urlaubstage', error);
      return null;
    }
  };

  // Update business days calculation when dates change
  useEffect(() => {
    const calculateDays = async () => {
      if (formData.startDate && formData.endDate) {
        const result = await calculateVacationDays(formData.startDate, formData.endDate);
        if (result) {
          setVacationDaysInfo(result);
          setBusinessDays(result.working_days);
        }
      } else {
        setVacationDaysInfo(null);
        setBusinessDays(0);
      }
    };

    calculateDays();
  }, [formData.startDate, formData.endDate]);

  // Load user's requests
  const loadMyRequests = async () => {
    try {
      setLoading(true);
      const antraege = await UrlaubsantragService.getMyUrlaubsantraegeUrlaubsantragGet();
      setMeineAntraege(antraege);
    } catch (error) {
      handleError('Fehler beim Laden der Urlaubsanträge', error);
    } finally {
      setLoading(false);
    }
  };

  // Load pending requests (for approvers)
  const loadPendingRequests = async () => {
    if (!isApprover) return;

    try {
      setLoading(true);
      const antraege = await UrlaubsantragService.getAllPendingUrlaubsanträge();
      setPendingAntraege(antraege);
    } catch (error) {
      handleError('Fehler beim Laden der offenen Anträge', error);
    } finally {
      setLoading(false);
    }
  };

  // Submit new vacation request
  const submitRequest = async () => {
    if (!formData.startDate || !formData.endDate) {
      setSnackbar({
        open: true,
        message: 'Bitte geben Sie Start- und Enddatum an',
        severity: 'error',
      });
      return;
    }

    if (checkForOverlap(formData.startDate, formData.endDate)) {
      setSnackbar({
        open: true,
        message: 'Es existiert bereits ein Urlaubsantrag für diesen Zeitraum',
        severity: 'error',
      });
      return;
    }

    // Format dates for API
    const formattedStartDate = formData.startDate.format('YYYY-MM-DD');
    const formattedEndDate = formData.endDate.format('YYYY-MM-DD');

    const newRequest: Urlaubsantrag = {
      beginn: formattedStartDate,
      ende: formattedEndDate,
      grund: formData.grund || undefined,
      notiz: formData.notiz || undefined,
    };

    try {
      setLoading(true);
      await UserService.requestVacation(businessDays);
      await UrlaubsantragService.createUrlaubsantragUrlaubsantragPost(newRequest);
      showSuccess('Urlaubsantrag erfolgreich eingereicht');
      setOpenDialog(false);
      resetForm();
      await loadMyRequests();
      await loadRemainingVacationDays(); // Reload to get updated pending days
    } catch (error) {
      handleError('Fehler beim Einreichen des Urlaubsantrags', error);
    } finally {
      setLoading(false);
    }
  };

  // Delete vacation request
  const deleteRequest = async (id: number) => {
    try {
      setLoading(true);
      await UrlaubsantragService.deleteUrlaubsantragUrlaubsantragDelete(id);
      showSuccess('Urlaubsantrag erfolgreich gelöscht');
      await loadMyRequests();
      await loadRemainingVacationDays(); // Reload to get updated pending days
    } catch (error) {
      handleError('Fehler beim Löschen des Urlaubsantrags', error);
    } finally {
      setLoading(false);
    }
  };

  // Process vacation request (approve/reject)
  const processRequest = async (id: number, status: 'Genehmigt' | 'Abgelehnt') => {
    try {
      setLoading(true);
      await UrlaubsantragService.bearbeiteUrlaubsantragDashboardUrlaubsantragBearbeitenPost(id, status);
      showSuccess(`Urlaubsantrag erfolgreich ${status === 'Genehmigt' ? 'genehmigt' : 'abgelehnt'}`);
      await loadPendingRequests();
      await loadRemainingVacationDays(); // Reload to get updated pending days
      await loadMyRequests();
    } catch (error) {
      handleError('Fehler bei der Bearbeitung des Urlaubsantrags', error);
    } finally {
      setLoading(false);
    }
  };

  // Reset form
  const resetForm = () => {
    setFormData({
      startDate: null,
      endDate: null,
      grund: '',
      notiz: '',
    });
  };

  // Funktion zum Laden der Urlaubstage-Informationen
  const loadRemainingVacationDays = async () => {
    try {
      setLoading(true);
      const response = await UserService.getUserVacationDays();
      setTotalVacationDays(response.total_vacation_days);
      setRemainingVacationDays(response.remaining_vacation_days);
      setPendingVacationDays(response.pending_vacation_days);
    } catch (error) {
      handleError('Fehler beim Laden der Urlaubstage-Informationen', error);
    } finally {
      setLoading(false);
    }
  };

  const checkForOverlap = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs): boolean => {
    if (!startDate || !endDate) return false;

    // Format dates for comparison
    const start = startDate.startOf('day');
    const end = endDate.startOf('day');

    // Check against existing requests
    return meineAntraege.some((antrag) => {
      const antragStart = dayjs(antrag.beginn).startOf('day');
      const antragEnd = dayjs(antrag.ende).startOf('day');

      // Check if dates overlap
      return ((start.isSame(antragStart) || start.isAfter(antragStart)) && start.isBefore(antragEnd)) || (end.isAfter(antragStart) && (end.isSame(antragEnd) || end.isBefore(antragEnd))) || (start.isBefore(antragStart) && end.isAfter(antragEnd));
    });
  };

  const getExistingVacationDates = (): {
    start: string;
    end: string;
    status: string;
    id: number;
  }[] => {
    return meineAntraege.map((antrag) => ({
      start: antrag.beginn,
      end: antrag.ende,
      status: antrag.status,
      id: antrag.id,
    }));
  };

  // Funktion zum Setzen der Urlaubstage für einen Benutzer
  const setVacationDays = async (userId: string, totalDays: number, remainingDays: number, pendingDays: number = 0) => {
    try {
      setLoading(true);
      await UserService.setVacationDaysForUser(userId, totalDays, remainingDays, pendingDays);
      showSuccess('Urlaubstage erfolgreich aktualisiert');

      // Wenn es der eigene Benutzer ist, aktualisiere die lokalen Werte
      if (userId === 'current') {
        // Annahme: 'current' repräsentiert den aktuellen Benutzer
        await loadRemainingVacationDays();
      }
    } catch (error) {
      handleError('Fehler beim Aktualisieren der Urlaubstage', error);
    } finally {
      setLoading(false);
    }
  };

  // Funktion zum Berechnen der projezierten verbleibenden Urlaubstage nach Einreichung
  const calculateProjectedRemainingDays = (): number => {
    if (!remainingVacationDays || !businessDays) return 0;
    return remainingVacationDays - (pendingVacationDays || 0) - businessDays;
  };

  // Funktion zum Berechnen der projezierten anhängigen Urlaubstage nach Einreichung
  const calculateProjectedPendingDays = (): number => {
    if (pendingVacationDays === null || !businessDays) return 0;
    return pendingVacationDays + businessDays;
  };

  // Initial data load
  useEffect(() => {
    loadMyRequests();
    loadRemainingVacationDays();
    if (isApprover) {
      loadPendingRequests();
    }
  }, [isApprover]);

  return {
    loading,
    meineAntraege,
    pendingAntraege,
    snackbar,
    setSnackbar,
    formData,
    setFormData,
    openDialog,
    setOpenDialog,
    businessDays,
    vacationDaysInfo,
    totalVacationDays,
    remainingVacationDays,
    pendingVacationDays,
    calculateProjectedRemainingDays,
    calculateProjectedPendingDays,
    deleteRequest,
    processRequest,
    submitRequest,
    resetForm,
    loadMyRequests,
    loadPendingRequests,
    loadRemainingVacationDays,
    setVacationDays,
    checkForOverlap, 
    getExistingVacationDates, 
  };
};
