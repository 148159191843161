import React, { ReactNode } from "react";

import APIWrapperContext from "./APIWrapperContext";


import { CancelablePromise } from "./openapi"
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
interface IAPIWrapperContextProviderProps {
  children?: ReactNode;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const APIWrapperContextProvider = ({
  children,
}: IAPIWrapperContextProviderProps) => {
  // Lift the value into the parent’s state: https://reactjs.org/docs/context.html#caveats
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState<"success"|"error"|"warning"|"info">("success");
  const [message, setMessage] = React.useState<string|undefined>(undefined);
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const call_backend_api = async (promise:CancelablePromise<any>,successHandler:Function|undefined=undefined,errorHandler:Function|undefined=undefined,finallyHandler:Function|undefined=undefined,showSnackbars=false,successMessage?:string,errorMessage?:string,showSnackbarsOnError:boolean=false)=>{
    promise.then((res)=>{
        if(successHandler){
            successHandler(res)
        }
        if(showSnackbars){
            setSeverity("success")
            if(successMessage){
                setMessage(successMessage)
            }
            else{
                setMessage(undefined)
            }
            setOpen(true)
        }
    })
    .catch((err)=>{
        console.log(err)
        if(errorHandler){
            errorHandler(err)
        }
        if(showSnackbars || showSnackbarsOnError){
            setSeverity("error")
            if(successMessage){
                setMessage(errorMessage)
            }
            else{
                setMessage(undefined)
            }
            setOpen(true)
        }

    })
    .finally(()=>{
        if(finallyHandler){
            finallyHandler()
        }
        
    }) 
}


  // const { token,setAuthed,setCheckingAuthed } = React.useContext(SecurityContext)

  return (
    <APIWrapperContext.Provider
      value={{
        call_backend_api,
      }}
    >
      {children}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          
          {message?message:severity+ ""}
        </Alert>
        {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}
      </Snackbar>
    </APIWrapperContext.Provider>
  );
};

export default APIWrapperContextProvider;






