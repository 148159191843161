import {Box, Icon, Paper, Typography} from "@mui/material";
import React, {useContext} from "react";
import APIWrapperContext from "../../../../../services/APIWrapperContext";
import {addDaysToDate,} from "../timehelperFunctions";

import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {AddComment, CheckCircle, Comment, RemoveCircle} from "@mui/icons-material";
import ZeiterfassungDashboardNoteContext, {NotizArbeitstagUser_id} from "../../../../../Contexts/ZeiterfassungDashboardContext/ZeiterfassungDashboardNoteContext";

interface IWorktimeDetails {
    id: number;
    bvh: number;
    DATUM: string;
    BEGINN: string;
    ENDE: string;
    PAUSE: number;
    DAUER: number;
    user_id: string;
    BEARBEITER: string;
    KURZINFO: string;
    NOTIZ: string;
    TAETIGKEIT: string;
    gewdet: string;
    PROJEKTNR: number;
    MANDNR: number;
    BVH: string;
    bvh_uuid: string;
}


function createData(
    DATUM: string,
    gesamt_arbeitszeit: number,
    gesamt_pause: number,
    arbeitszeit_details: IWorktimeDetails[],
    user_id: string,
    BEARBEITER: string,
    note: NotizArbeitstagUser_id[] = []
) {
    let arbeitszeit_erfuellt: boolean = gesamt_arbeitszeit >= 8.5;
    return {
        DATUM,
        gesamt_arbeitszeit,
        gesamt_pause,
        user_id,
        BEARBEITER,
        arbeitszeit_details,
        arbeitszeit_erfuellt,
        note
    };
}

function Row(props: { row: ReturnType<typeof createData> }) {
    const {row} = props;
    const [open, setOpen] = React.useState(false);

    const {call_backend_api} = useContext(APIWrapperContext)

    const {preSetNote} = useContext(ZeiterfassungDashboardNoteContext)


    return (
        <React.Fragment>
            <TableRow sx={{"& > *": {borderBottom: "unset"}}}>
                <TableCell>
                    {(row.arbeitszeit_details.length > 0) &&
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    }
                </TableCell>
                {/* <TableCell component="th" scope="row">
          {row.DATUM}
        </TableCell> */}
                <TableCell align="left">{row.BEARBEITER}</TableCell>
                <TableCell align="right">{row.gesamt_arbeitszeit.toFixed(2)}</TableCell>
                <TableCell align="right">{row.gesamt_pause.toFixed(2)}</TableCell>
                <TableCell align="right" sx={{
                    color: row.arbeitszeit_erfuellt ? "green" : "red"
                }}>
                    <Box sx={{display: "flex", flexDirection: "row", float: 'right'}}>
                        <Typography>

                            {row.arbeitszeit_details.length > 0 ? (row.arbeitszeit_erfuellt ? (row.gesamt_arbeitszeit > 8.5 ? 'AZ überschritten' : '') : 'AZ unterschritten') : "Kein Eintrag"}
                            {}
                        </Typography>
                        <Icon>
                            {
                                row.arbeitszeit_erfuellt ?
                                    <CheckCircle/> : <RemoveCircle/>}
                        </Icon>
                    </Box>
                </TableCell>
                <TableCell align="right">
                    <>
                        <IconButton sx={{color: row.note.length ? 'blue' : 'gray'}} onClick={() => {
                            preSetNote(row.note.length ? row.note[0] : {
                                id: undefined,
                                datum: row.DATUM,
                                notiz: "",
                                user_id: row.user_id
                            })
                        }} title={String(row.note.length ? row.note.map(el => el.notiz).join("") : 'Neue Notiz')}>
                            {row.note ? <Comment/> : <AddComment/>}
                        </IconButton>
                    </>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Typography variant="h6" gutterBottom component="div">
                                Details
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Bauvorhaben</TableCell>
                                        <TableCell align="right">Beginn</TableCell>
                                        <TableCell align="right">Ende</TableCell>
                                        <TableCell align="right">Dauer</TableCell>
                                        <TableCell align="right">Pause</TableCell>
                                        <TableCell>Tätigkeit</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.arbeitszeit_details.map((detailRow) => (
                                        <TableRow key={detailRow.id}>
                                            <TableCell component="th" scope="row">
                                                {detailRow.BVH}
                                            </TableCell>
                                            <TableCell align="right">{detailRow.BEGINN}</TableCell>
                                            <TableCell align="right">{detailRow.ENDE}</TableCell>
                                            <TableCell align="right">{detailRow.DAUER.toFixed(2)}</TableCell>
                                            <TableCell align="right">{detailRow.PAUSE.toFixed(2)}</TableCell>
                                            <TableCell>
                                                {detailRow.TAETIGKEIT}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
//   createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
//   createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
//   createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
// ];
interface ITimeRange {
    start: Date,
    end: Date
}

interface IWorkingDataOverviewTableProps {
    data: any[];
    user_zeiterfassung: any[],
    timeRange: ITimeRange,
    notes: any[]
}

const convertDateToGermanString = (date: Date) => {
    return date.getDate().toString().padStart(2, '0') + "." + (date.getMonth() + 1).toString().padStart(2, '0') + "." + date.getFullYear()
}

const convertGermanStringToDate = (dateString: string) => {
    let tmp = dateString.split(".");
    return new Date(Number(tmp[2]), Number(tmp[1]) - 1, Number(tmp[0]));
}

const convertDateStringToGermanString = (dateString: string) => {
    let tmp = dateString.split("-");
    return tmp[2] + "." + tmp[1] + "." + tmp[0];
}


const aggregateData = (data: any[], user_zeiterfassung: any[], timeRange: ITimeRange, notes: any[]) => {
    let dict_arbeitszeit: any = {};
    let tmp_date = timeRange.start;
    while (tmp_date <= timeRange.end) {
        if (tmp_date.getDay() === 0 || tmp_date.getDay() === 6) {
            tmp_date = addDaysToDate(tmp_date, 1);
            continue;
        }
        let tmp_date_str = convertDateToGermanString(tmp_date);
        dict_arbeitszeit[tmp_date_str] = {};
        dict_arbeitszeit[tmp_date_str].mitarbeiter = {};
        user_zeiterfassung.forEach((el) => {
            dict_arbeitszeit[tmp_date_str].mitarbeiter[el.user_id] = {};
            dict_arbeitszeit[tmp_date_str].mitarbeiter[el.user_id].gesamt_arbeitszeit = 0;
            dict_arbeitszeit[tmp_date_str].mitarbeiter[el.user_id].gesamt_pause = 0;
            dict_arbeitszeit[tmp_date_str].mitarbeiter[el.user_id].arbeitszeit_details = [];
            dict_arbeitszeit[tmp_date_str].mitarbeiter[el.user_id].BEARBEITER = (el.fullname ? el.fullname : el.email) + " (" + (el.NameAbbreviation ? el.NameAbbreviation : "---") + ")";
            dict_arbeitszeit[tmp_date_str].mitarbeiter[el.user_id].notes = [];
        });

        tmp_date = addDaysToDate(tmp_date, 1);
    }
    if (notes !== undefined) {
        notes.forEach((el) => {
            if (dict_arbeitszeit[convertDateStringToGermanString(el.datum)]?.mitarbeiter[el.user_id] && 'notes' in dict_arbeitszeit[convertDateStringToGermanString(el.datum)].mitarbeiter[el.user_id]) {
                try {
                    dict_arbeitszeit[convertDateStringToGermanString(el.datum)].mitarbeiter[el.user_id].notes.push(el)
                } catch (e) {
                    console.log(e)
                }
            }
        })
    }

    if (data !== undefined) {
        data.forEach((el) => {

            if (!(el.DATUM in dict_arbeitszeit)) {
                dict_arbeitszeit[el.DATUM] = {};
                dict_arbeitszeit[el.DATUM].mitarbeiter = {};
            }
            if (!(el.user_id in dict_arbeitszeit[el.DATUM].mitarbeiter)) {
                dict_arbeitszeit[el.DATUM].mitarbeiter[el.user_id] = {};
                dict_arbeitszeit[el.DATUM].mitarbeiter[
                    el.user_id
                    ].gesamt_arbeitszeit = 0;
                dict_arbeitszeit[el.DATUM].mitarbeiter[el.user_id].gesamt_pause = 0;
                dict_arbeitszeit[el.DATUM].mitarbeiter[el.user_id].arbeitszeit_details =
                    [];
                if (el.fullname) {
                    dict_arbeitszeit[el.DATUM].mitarbeiter[el.user_id].BEARBEITER =
                        el.user_id + " " + el.DATUM;
                } else {
                    const u = user_zeiterfassung.find(u => u.user_id === el.user_id);
                    if (u) {
                        dict_arbeitszeit[el.DATUM].mitarbeiter[el.user_id].BEARBEITER =
                            (u.fullname ? u.fullname : u.email) + " (" + (u.NameAbbreviation ? u.NameAbbreviation : "---") + ")";
                    } else {
                        dict_arbeitszeit[el.DATUM].mitarbeiter[el.user_id].BEARBEITER =
                            el.user_id + " " + el.DATUM;
                    }
                }

            }

            dict_arbeitszeit[el.DATUM].mitarbeiter[el.user_id].gesamt_arbeitszeit +=
                el.DAUER;
            dict_arbeitszeit[el.DATUM].mitarbeiter[el.user_id].gesamt_pause +=
                el.PAUSE;
            dict_arbeitszeit[el.DATUM].mitarbeiter[
                el.user_id
                ].arbeitszeit_details.push(el);
        });
    }
    let rows_tmp: any[] = [];
    for (const [key, value] of Object.entries(dict_arbeitszeit)) {
        for (const [key2, value2] of Object.entries((value as any).mitarbeiter)) {
            const value2_tmp = value2 as any;
            rows_tmp.push({
                DATUM: key,
                gesamt_arbeitszeit: value2_tmp.gesamt_arbeitszeit,
                gesamt_pause: value2_tmp.gesamt_pause,
                arbeitszeit_details: value2_tmp.arbeitszeit_details,
                user_id: key2,
                BEARBEITER: value2_tmp.BEARBEITER,
                note: value2_tmp.notes
            });
        }
    }


    rows_tmp.sort((a, b) => {
        if (convertGermanStringToDate(a.DATUM) > convertGermanStringToDate(b.DATUM)) {
            return 1;
        } else if (convertGermanStringToDate(a.DATUM) < convertGermanStringToDate(b.DATUM)) {
            return -1;
        } else {
            return 0;
        }
    });
    return rows_tmp;
};

const Days_List = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

export default function WorkingDataOverviewTable(
    props: IWorkingDataOverviewTableProps
) {
    const {data, user_zeiterfassung, timeRange, notes} = props;
    const tmp_data = aggregateData(data, user_zeiterfassung, timeRange, notes);
    let rows =
        tmp_data !== undefined
            ? tmp_data.map((el) => {
                return createData(
                    el.DATUM,
                    el.gesamt_arbeitszeit,
                    el.gesamt_pause,
                    el.arbeitszeit_details,
                    el.user_id,
                    el.BEARBEITER,
                    el.note
                );
            })
            : [];

    return (
        <TableContainer component={Paper} sx={{maxHeight: "50vh"}}>
            <Table stickyHeader aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell/>

                        {/* <TableCell>Datum</TableCell> */}
                        <TableCell align="left">Mitarbeiter/-in</TableCell>
                        <TableCell align="right">Arbeitszeit (h)</TableCell>
                        <TableCell align="right">Pausenzeit (h)</TableCell>
                        <TableCell align="right">Arbeitszeit erfüllt</TableCell>
                        <TableCell align="right">Notiz</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, k) => {
                        if (k !== 0 && rows[k - 1].DATUM === row.DATUM) {
                            return (
                                <Row key={row.DATUM + String(row.user_id)} row={row}/>
                            )

                        } else {
                            return (<>
                                <TableRow sx={{"& > *": {borderBottom: "unset"}}}>
                                    <TableCell>
                                        <b>
                                            {Days_List[convertGermanStringToDate(row.DATUM).getDay()] + ", " + row.DATUM}
                                        </b>


                                    </TableCell>
                                    <TableCell/><TableCell/><TableCell/><TableCell/><TableCell/>
                                </TableRow>
                                <Row key={row.DATUM + String(row.user_id)} row={row}/>
                            </>)
                        }


                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
