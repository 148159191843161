import React, {ReactNode, useContext, useEffect} from "react";

import ContextViewFileList, {I_dir_id_history} from "./ContextViewFileList";
import {AccessibleDocument, DirView, DocumentsService,} from "../../services/openapi";
import APIWrapperContext from "../../services/APIWrapperContext";

interface IContextProviderViewFileListProps {
    children?: ReactNode;
    user_specific?: boolean;
    hausverwaltung?: boolean;
}

const ContextProviderViewFileList = ({
                                         children,
                                         user_specific,
                                         hausverwaltung
                                     }: IContextProviderViewFileListProps) => {
    // Lift the value into the parent’s state: https://reactjs.org/docs/context.html#caveats

    const {call_backend_api} = useContext(APIWrapperContext);

    const [dir_id_history, setDirIdHistory] = React.useState<I_dir_id_history[]>(
        []
    );
    const [curr_dir_id, setCurrDirId] = React.useState<string | undefined>();
    const [curr_dir_view, setCurrDirView] = React.useState<DirView | {}>({});
    const [loading_dir, setLoadingDir] = React.useState<boolean>(false);
    const [accessible_elements, setAccessibleElements] = React.useState<
        Record<string, AccessibleDocument>
    >({});

    const update_accessible_elements = () => {
        if (user_specific) {
            return;
        } else {
            call_backend_api(
                DocumentsService.getAccessibleDocsDocGetAccessibleDocsGet(),
                (response: Record<string, AccessibleDocument>) => {
                    setAccessibleElements(response);
                },
                undefined,
                undefined,
                false, undefined, "Laden der Dokumente fehlgeschlagen", true
            );
        }
    };

    const dir_navigate_back = (id?: string) => {
        if (dir_id_history.length > 1) {
            let tmp_dir_id_history = dir_id_history;
            if (id) {
                // tmp_dir_id_history = dir_id_history.filter((el)=>el.id!==id);

                setCurrDirId(id);
                setDirIdHistory(
                    tmp_dir_id_history.slice(
                        0,
                        tmp_dir_id_history.findIndex((el) => el.id === id) + 1
                    )
                );
            } else {
                setCurrDirId(tmp_dir_id_history[tmp_dir_id_history.length - 2].id);
                setDirIdHistory(
                    tmp_dir_id_history.slice(0, tmp_dir_id_history.length - 1)
                );
            }

        }
    };
    const dir_navigate_forward = (id: string) => {
        // let tmp_dir_id_history = dir_id_history;
        // tmp_dir_id_history.push(id);
        if ("children" in curr_dir_view) {
            let name = curr_dir_view.children?.find(
                (el) => el.hidrive_id === id
            )?.parent;
            setDirIdHistory([...dir_id_history, {id: id, name: name ? name : ""}]);
        }
        setCurrDirId(id);
    };

    const update_dir_view = (id: string | undefined) => {
        if (user_specific) {
            setLoadingDir(true);
            call_backend_api(
                DocumentsService.hidriveGetFilesUserDocGetDirContentUserGet(id),
                (response: DirView) => {
                    console.log(response)
                    setCurrDirView(response);
                    if (id === undefined) {
                        setDirIdHistory([
                            ...dir_id_history,
                            {id: response.hidrive_id, name: response.parent},
                        ]);
                    }
                },
                undefined,
                () => {
                    setLoadingDir(false)
                },
                false, undefined, "Laden des Ordners fehlgeschlagen", true
            );
        } else {
            setLoadingDir(true);
            call_backend_api(
                DocumentsService.hidriveGetFilesDocGetDirContentGet(id, hausverwaltung ? hausverwaltung : false),
                (response: DirView) => {
                    setCurrDirView(response);
                    if (id === undefined) {
                        setDirIdHistory([
                            ...dir_id_history,
                            {id: response.hidrive_id, name: response.parent},
                        ]);
                    }
                    update_accessible_elements()
                },
                undefined,
                () => {
                    setLoadingDir(false)
                },
                false, undefined, "Laden des Ordners fehlgeschlagen", true
            );
        }
    };
    const init_dir_view = () => {
        update_accessible_elements();

        update_dir_view(undefined);
    };

    useEffect(() => {
        update_dir_view(curr_dir_id);
    }, [curr_dir_id]);

    useEffect(() => {
    }, [dir_id_history]);

    return (
        <ContextViewFileList.Provider
            value={{
                dir_id_history,
                curr_dir_id,
                curr_dir_view,
                accessible_elements,
                user_specific,
                loading_dir,
                update_accessible_elements,
                setCurrDirId,
                setCurrDirView,
                init_dir_view,
                dir_navigate_back,
                dir_navigate_forward,
                update_dir_view
            }}
        >
            {children}
        </ContextViewFileList.Provider>
    );
};

export default ContextProviderViewFileList;
