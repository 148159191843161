import React from 'react';
import {Box} from '@mui/material';
import UrlaubsantragOverview from './UrlaubsantragOverview';
import UrlaubsantragTable from './UrlaubsantragTable';
import {useUrlaubsantrag} from '../../../../Contexts/Urlaubsantrag/useUrlaubsantrag';
import UserContext from '../../../../Contexts/UserContext/UserContext';
import {get} from 'http';

const Urlaubsantrag = () => {
  let isApprover = false;
  const {user_roll, email, name} = React.useContext(UserContext);
  if (user_roll?.includes('Administrator') || user_roll?.includes('Admin Zeiterfassung')) {
    isApprover = true;
  }

  // Use the hook in the parent component
  const {
    loading,
    meineAntraege,
    pendingAntraege,
    snackbar,
    setSnackbar,
    formData,
    setFormData,
    openDialog,
    setOpenDialog,
    vacationDaysInfo,
    totalVacationDays,
    remainingVacationDays,
    pendingVacationDays,
    calculateProjectedRemainingDays,
    calculateProjectedPendingDays,
    deleteRequest,
    processRequest,
    submitRequest,
    resetForm,
    loadPendingRequests,
    getExistingVacationDates,
    checkForOverlap,
  } = useUrlaubsantrag(isApprover);

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
      {/* Pass only the needed props to Overview */}
      <UrlaubsantragOverview
        loading={loading}
        totalVacationDays={totalVacationDays}
        remainingVacationDays={remainingVacationDays}
        pendingVacationDays={pendingVacationDays}
      />

      {/* Pass all hook props to Table */}
      <UrlaubsantragTable
        loading={loading}
        meineAntraege={meineAntraege}
        pendingAntraege={pendingAntraege}
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        formData={formData}
        setFormData={setFormData}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        vacationDaysInfo={vacationDaysInfo}
        remainingVacationDays={remainingVacationDays}
        pendingVacationDays={pendingVacationDays}
        calculateProjectedRemainingDays={calculateProjectedRemainingDays}
        calculateProjectedPendingDays={calculateProjectedPendingDays}
        deleteRequest={deleteRequest}
        processRequest={processRequest}
        submitRequest={submitRequest}
        resetForm={resetForm}
        loadPendingRequests={loadPendingRequests}
        isApprover={isApprover}
        existingVacationDates={getExistingVacationDates()}
        checkForOverlap={checkForOverlap}
      />
    </Box>
  );
};

export default Urlaubsantrag;
