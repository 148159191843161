import SuperTokens from "supertokens-auth-react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
import {languageTranslations} from "./SuperTokens/superTokens_translations";
import EmailVerification from "supertokens-auth-react/recipe/emailverification";

export const init_supertokens = () => {
    SuperTokens.init({
      languageTranslations: languageTranslations,
      appInfo: {
        // learn more about this on https://supertokens.com/docs/emailpassword/appinfo
        appName: 'test',
        apiDomain:
          !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
            ? 'http://localhost:8008'
            : process.env.REACT_APP_BACKEND_URL
            ? process.env.REACT_APP_BACKEND_URL
            : '',
        websiteDomain:
          !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
            ? 'http://localhost:3000'
            : process.env.REACT_APP_FRONTEND_URL
            ? process.env.REACT_APP_FRONTEND_URL
            : '',
        apiBasePath: '/',
        websiteBasePath: '/auth',
      },
      recipeList: [
        EmailVerification.init({
          mode: 'REQUIRED', // or "OPTIONAL"
        }),
        EmailPassword.init({
          style: `
                  [data-supertokens~=superTokensBranding] {
                      display:none;
                  }
                  
                  [data-supertokens~=container] {
                    box-shadow: none;
                    margin-top:0px;
                    margin-bottom:0px;
                    font-family:'Dax-Pro-Regular';
                    --palette-primary: 53, 122, 193;
                  }
                  [data-supertokens~=button] {
                    color: white;
                    border-color: #357bc1;
                    background-color:#357bc1;
                  }
                  [data-supertokens~=row] {
                    padding-top:10px;
                    padding-bottom:10px
                  }
                  [data-supertokens~=formRow ] {
                    padding-top:10px;
                    padding-bottom:10px
                  }
              `,
          useShadowDom: false,

          signInAndUpFeature: {
            signUpForm: {
              formFields: [
                {
                  id: 'name',
                  label: 'Vor- und Nachname',
                  placeholder: 'Max Mustermann',
                },
                {
                  id: 'registrationCode',
                  label: 'Registrierungscode',
                  placeholder: '',
                },
                {
                  id: 'terms',
                  label: '',
                  optional: false,
                  nonOptionalErrorMsg:
                    'Sie müssen die Nutzungsbedingungen und Datenschutzrichtlinien akzeptieren',
                  inputComponent: ({name, onChange}) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        marginBottom: ' -12px',
                      }}>
                      <input
                        name={name}
                        type='checkbox'
                        onChange={(e) => onChange(e.target.checked.toString())}></input>
                      <span style={{marginLeft: 5, textAlign: 'left'}}>
                        Ich stimme den{' '}
                        <a
                          href={
                            (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
                              ? 'http://localhost:3000'
                              : process.env.REACT_APP_FRONTEND_URL
                              ? process.env.REACT_APP_FRONTEND_URL
                              : '') + '/terms-of-service'
                          }
                          data-supertokens='link'>
                          Nutzungsbedingungen
                        </a>
                        und{' '}
                        <a
                          href={
                            (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
                              ? 'http://localhost:3000'
                              : process.env.REACT_APP_FRONTEND_URL
                              ? process.env.REACT_APP_FRONTEND_URL
                              : '') + '/privacy-policy'
                          }
                          data-supertokens='link'>
                          Datenschutzrichtlinien
                        </a>
                        zu
                      </span>
                    </div>
                  ),
                },
              ],
              // termsOfServiceLink:
              //   (!process.env.NODE_ENV || process.env.NODE_ENV === "development"
              //     ? "http://localhost:3000"
              //     : process.env.REACT_APP_FRONTEND_URL
              //     ? process.env.REACT_APP_FRONTEND_URL
              //     : "") + "/terms-of-service",
              // privacyPolicyLink:
              //   (!process.env.NODE_ENV || process.env.NODE_ENV === "development"
              //     ? "http://localhost:3000"
              //     : process.env.REACT_APP_FRONTEND_URL
              //     ? process.env.REACT_APP_FRONTEND_URL
              //     : "") + "/privacy-policy",
            },
          },
        }),
        Session.init(),
      ],
    });
};
