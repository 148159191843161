import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { restore_slashes } from "../background_functions";
import ContextProviderElementFunctions from "../Contexts/ContectProviderElementFunctions";
import { UploadFile } from "../ElementFunctions/UploadFile/UploadFile";
import { AddFolder } from "../ElementFunctions/AddFolder/AddFolder";

import { I_dir_id_history } from "../Contexts/ContextViewFileList";

interface NavigationBarProps {
  dirIdHistory: I_dir_id_history[];
  currDirId: string | undefined;
  currDirView: any;
  userSpecific: boolean;
  dirNavigateBack: () => void;
}

export const NavigationBar: React.FC<NavigationBarProps> = ({
  dirIdHistory,
  currDirId,
  currDirView,
  userSpecific,
  dirNavigateBack
}) => {
  if (dirIdHistory.length <= 1) return null;

  return (
    <Box sx={{ display: "flex", backgroundColor: "#f0f0f0" }}>
      <IconButton 
        onClick={dirNavigateBack} 
        title={"Zurück"} 
        disabled={dirIdHistory.length < 2}
      >
        <ArrowBack />
      </IconButton>
      
      {currDirView && "parent" in currDirView && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flex: 1 }} />
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {restore_slashes(currDirView.parent)}
          </Typography>
          <Box sx={{ flex: 1 }} />
        </Box>
      )}
      
      <Box sx={{ flex: 1 }} />
      
      {currDirId !== undefined &&
        currDirView &&
        "parent" in currDirView && 
        userSpecific === false && (
          <Box>
            <ContextProviderElementFunctions
              key={"ContextProviderElementFunctions_" + currDirId}
              curr_dir_id={currDirId}
              type={"dir"}
              name={restore_slashes(currDirView.parent)}
            >
              <UploadFile />
              <AddFolder />
            </ContextProviderElementFunctions>
          </Box>
        )}
    </Box>
  );
};