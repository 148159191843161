import React from 'react';
import {TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Box, Typography, IconButton, CircularProgress} from '@mui/material';
import {Delete as DeleteIcon} from '@mui/icons-material';
import dayjs from 'dayjs';
import { MeineAntraegeTabProps } from '../../../../services/openapi/models/Urlaubsantrag';
import { StatusChip } from './UrlaubsantragStatusChip';

export const MeineAntraegeTab: React.FC<MeineAntraegeTabProps> = ({loading, antraege, isApprover, onDelete}) => {
  // Format date for display
  const formatDate = (dateString: string) => {
    return dayjs(dateString).format('DD.MM.YYYY');
  };

  if (loading) {
    return (
      <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}>
        <CircularProgress />
      </Box>
    );
  }

  if (antraege.length === 0) {
    return (
      <Box sx={{p: 2}}>
        <Typography variant='body1'>Keine Urlaubsanträge vorhanden</Typography>
      </Box>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Von</TableCell>
            <TableCell>Bis</TableCell>
            <TableCell>Grund</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Bearbeitet am</TableCell>
            <TableCell>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {antraege.map((antrag) => (
            <TableRow key={antrag.id}>
              <TableCell>{formatDate(antrag.beginn)}</TableCell>
              <TableCell>{formatDate(antrag.ende)}</TableCell>
              <TableCell>{antrag.grund || '-'}</TableCell>
              <TableCell>
                <StatusChip status={antrag.status} />
              </TableCell>
              <TableCell>{antrag.bearbeitung_datum ? formatDate(antrag.bearbeitung_datum) : '-'}</TableCell>
              <TableCell>
                {(antrag.status === 'Beantragt' || isApprover) && (
                  <IconButton size='small' color='error' onClick={() => onDelete(antrag.id)} title='Antrag löschen'>
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
