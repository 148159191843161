import React, {useState} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, Box, Typography, CircularProgress, Card, Accordion, AccordionSummary, AccordionDetails, Divider, Alert, Tooltip} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {PickersDay, PickersDayProps} from '@mui/x-date-pickers/PickersDay';
import {ExpandMore as ExpandMoreIcon, WorkOutline as WorkIcon, EventAvailable as EventAvailableIcon} from '@mui/icons-material';
import dayjs, {Dayjs} from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {VacationDaysResponse} from '../../../../services/openapi/models/Urlaubsantrag';

// Extend dayjs with isBetween plugin
dayjs.extend(isBetween);

interface VacationPeriod {
  start: string;
  end: string;
  status: string;
  id: number;
}

interface FormData {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  grund: string;
  notiz: string;
}

interface UrlaubsantragFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  formData: FormData;
  setFormData: (value: FormData) => void;
  vacationDaysInfo: VacationDaysResponse | null;
  remainingVacationDays: number | null;
  pendingVacationDays: number | null;
  calculateProjectedRemainingDays: () => number;
  calculateProjectedPendingDays: () => number;
  existingVacationDates: VacationPeriod[]; // For highlighting calendar dates
  checkForOverlap: (start: Dayjs, end: Dayjs) => boolean; // For checking date overlaps
}

// Create a custom day component
interface ServerDayProps extends PickersDayProps<Dayjs> {
  existingVacationDates: VacationPeriod[];
}

function ServerDay(props: ServerDayProps) {
  const {day, existingVacationDates, ...other} = props;

  // Check if current date is in any vacation period
  const isInVacationPeriod = existingVacationDates.some((period) => {
    const periodStart = dayjs(period.start);
    const periodEnd = dayjs(period.end);
    return day.isBetween(periodStart, periodEnd, 'day', '[]');
  });

  // Find a vacation period that contains this date (for the tooltip)
  const vacationPeriod = existingVacationDates.find((period) => {
    const periodStart = dayjs(period.start);
    const periodEnd = dayjs(period.end);
    return day.isBetween(periodStart, periodEnd, 'day', '[]');
  });

  // Get status color
  const getStatusColor = (status: string): string => {
    switch (status) {
      case 'Genehmigt':
        return '#4caf50'; // Green
      case 'Abgelehnt':
        return '#f44336'; // Red
      case 'Ausstehend':
        return '#ff9800'; // Orange
      default:
        return '#2196f3'; // Blue
    }
  };

  // Create the styled day element
  const dayElement = (
    <Box
      sx={{
        position: 'relative',
        ...(isInVacationPeriod && {
          backgroundColor: vacationPeriod ? `${getStatusColor(vacationPeriod.status)}20` : 'transparent', // 20 for transparency
          borderRadius: '50%',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 2,
            left: '50%',
            transform: 'translateX(-50%)',
            width: 4,
            height: 4,
            borderRadius: '50%',
            backgroundColor: vacationPeriod ? getStatusColor(vacationPeriod.status) : 'primary.main',
          },
        }),
      }}>
      <PickersDay day={day} {...other} />
    </Box>
  );

  // Add tooltip if in vacation period
  return isInVacationPeriod && vacationPeriod ? <Tooltip title={`Urlaub: ${dayjs(vacationPeriod.start).format('DD.MM.YYYY')} - ${dayjs(vacationPeriod.end).format('DD.MM.YYYY')} (${vacationPeriod.status})`}>{dayElement}</Tooltip> : dayElement;
}

export const UrlaubsantragForm: React.FC<UrlaubsantragFormProps> = ({open, onClose, onSubmit, loading, formData, setFormData, vacationDaysInfo, remainingVacationDays, pendingVacationDays, calculateProjectedRemainingDays, calculateProjectedPendingDays, existingVacationDates, checkForOverlap}) => {
  const [expanded, setExpanded] = useState(false);

  // Calculate weekend days count
  const getWeekendDaysCount = (): number => {
    if (!vacationDaysInfo) return 0;
    return vacationDaysInfo.total_days - vacationDaysInfo.working_days - vacationDaysInfo.holiday_count;
  };

  // Berechne verbleibende Urlaubstage nach diesem Antrag
  const projectedRemainingDays = calculateProjectedRemainingDays();
  const projectedPendingDays = calculateProjectedPendingDays();
  const hasInsufficientDays = projectedRemainingDays < 0;

  // Check for date overlap
  const hasOverlap = formData.startDate && formData.endDate && checkForOverlap(formData.startDate, formData.endDate) || false;

  // Calculate actual available days (remaining minus pending)
  const actualAvailableDays = remainingVacationDays !== null && pendingVacationDays !== null ? remainingVacationDays - pendingVacationDays : 0;

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>Neuer Urlaubsantrag</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3} sx={{mt: 1}}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label='Von'
                value={formData.startDate}
                onChange={(newValue) => {
                  setFormData({...formData, startDate: newValue});
                }}
                format='DD.MM.YYYY'
                slotProps={{
                  textField: {fullWidth: true},
                  day: {existingVacationDates} as any,
                }}
                slots={{
                  day: ServerDay as React.ComponentType<PickersDayProps<Dayjs>>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label='Bis'
                value={formData.endDate}
                onChange={(newValue) => {
                  setFormData({...formData, endDate: newValue});
                }}
                format='DD.MM.YYYY'
                minDate={formData.startDate || undefined}
                slotProps={{
                  textField: {fullWidth: true},
                  day: {existingVacationDates} as any,
                }}
                slots={{
                  day: ServerDay as React.ComponentType<PickersDayProps<Dayjs>>,
                }}
              />
            </Grid>

            {/* Show overlap warning if dates overlap */}
            {hasOverlap && (
              <Grid item xs={12}>
                <Alert severity='warning'>Achtung: Es existiert bereits ein Urlaubsantrag für diesen Zeitraum!</Alert>
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField label='Grund' fullWidth value={formData.grund} onChange={(e) => setFormData({...formData, grund: e.target.value})} />
            </Grid>
            <Grid item xs={12}>
              <TextField label='Notiz' fullWidth multiline rows={3} value={formData.notiz} onChange={(e) => setFormData({...formData, notiz: e.target.value})} />
            </Grid>

            {/* Urlaubstage-Übersicht */}
            <Grid item xs={12}>
              {remainingVacationDays !== null && (
                <Card variant='outlined' sx={{mb: 2, backgroundColor: '#f8f9fa'}}>
                  <Box sx={{p: 2}}>
                    <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                      <EventAvailableIcon sx={{mr: 1, color: 'primary.main'}} />
                      <Typography variant='body1' fontWeight='medium'>
                        Urlaubstage-Übersicht
                      </Typography>
                    </Box>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant='body2' color='text.secondary'>
                          Verfügbar insgesamt:
                        </Typography>
                        <Typography variant='body1'>30 Tage</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='body2' color='text.secondary'>
                          Bereits genutzt:
                        </Typography>
                        <Typography variant='body1'>{30 - (remainingVacationDays || 0)} Tage</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='body2' color='text.secondary'>
                          Beantragt/ausstehend:
                        </Typography>
                        <Typography variant='body1'>{pendingVacationDays || 0} Tage</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='body2' color='text.secondary'>
                          Aktuell verfügbar:
                        </Typography>
                        <Typography variant='body1' fontWeight='medium'>
                          {actualAvailableDays} Tage
                        </Typography>
                      </Grid>
                    </Grid>

                    {vacationDaysInfo && (
                      <>
                        <Divider sx={{my: 2}} />
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                          <Typography variant='body2' fontWeight='medium'>
                            Verfügbar nach Antrag:
                          </Typography>
                          <Typography variant='body1' fontWeight='bold' color={hasInsufficientDays ? 'error.main' : 'success.main'}>
                            {projectedRemainingDays} Tage
                          </Typography>
                        </Box>

                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1}}>
                          <Typography variant='body2' fontWeight='medium'>
                            Ausstehend nach Antrag:
                          </Typography>
                          <Typography variant='body1' fontWeight='medium'>
                            {projectedPendingDays} Tage
                          </Typography>
                        </Box>

                        {hasInsufficientDays && (
                          <Alert severity='error' sx={{mt: 1}}>
                            Nicht genügend Urlaubstage verfügbar! Sie überziehen Ihr Kontingent um {Math.abs(projectedRemainingDays)} Tage.
                          </Alert>
                        )}
                      </>
                    )}
                  </Box>
                </Card>
              )}
            </Grid>

            {vacationDaysInfo && (
              <Grid item xs={12}>
                <Card variant='outlined' sx={{backgroundColor: '#f5f9ff'}}>
                  <Box sx={{display: 'flex', alignItems: 'center', p: 2}}>
                    <WorkIcon sx={{mr: 1, color: 'primary.main'}} />
                    <Typography variant='body1' fontWeight='medium'>
                      {vacationDaysInfo.working_days} Arbeitstag{vacationDaysInfo.working_days !== 1 ? 'e' : ''}
                    </Typography>
                  </Box>

                  <Accordion
                    expanded={expanded}
                    onChange={() => setExpanded(!expanded)}
                    elevation={0}
                    disableGutters
                    sx={{
                      '&:before': {display: 'none'},
                      backgroundColor: 'transparent',
                      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                    }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{px: 2, minHeight: 40}}>
                      <Typography variant='body2' color='text.secondary'>
                        Details anzeigen
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{px: 2, pt: 0, pb: 2}}>
                      <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 1}}>
                        <Typography variant='body2' color='text.secondary'>
                          Gesamtzeitraum:
                        </Typography>
                        <Typography variant='body2'>
                          {vacationDaysInfo.total_days} Tag{vacationDaysInfo.total_days !== 1 ? 'e' : ''}
                        </Typography>
                      </Box>

                      {getWeekendDaysCount() > 0 && (
                        <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 1}}>
                          <Typography variant='body2' color='text.secondary'>
                            Wochenendtage:
                          </Typography>
                          <Typography variant='body2'>
                            {getWeekendDaysCount()} Tag{getWeekendDaysCount() !== 1 ? 'e' : ''}
                          </Typography>
                        </Box>
                      )}

                      {vacationDaysInfo.holiday_count > 0 && (
                        <>
                          <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 1}}>
                            <Typography variant='body2' color='text.secondary'>
                              Feiertage:
                            </Typography>
                            <Typography variant='body2'>
                              {vacationDaysInfo.holiday_count} Tag{vacationDaysInfo.holiday_count !== 1 ? 'e' : ''}
                            </Typography>
                          </Box>
                          <Typography variant='caption' color='text.secondary' sx={{display: 'block', mt: 0.5}}>
                            {vacationDaysInfo.holiday_names.join(', ')}
                          </Typography>
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Card>
              </Grid>
            )}
          </Grid>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='inherit' disabled={loading}>
          Abbrechen
        </Button>
        <Button onClick={onSubmit} variant='contained' color='primary' disabled={loading || !formData.startDate || !formData.endDate || hasInsufficientDays || hasOverlap} startIcon={loading ? <CircularProgress size={20} /> : undefined}>
          Einreichen
        </Button>
      </DialogActions>
    </Dialog>
  );
};
