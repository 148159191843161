import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {Box} from '@mui/material';
import ZeiterfassungContext from '../../../../Contexts/ZeiterfassungContext/ZeiterfassungContext';

interface IZeiterfassungSelectBauvorhabenProps {}

export default function ZeiterfassungSelectBauvorhaben(props: IZeiterfassungSelectBauvorhabenProps) {
  const {} = props;
  const {bauvorhaben, setBauvorhaben, bauvorhabenList} = React.useContext(ZeiterfassungContext);

  const handleChange = (event: SelectChangeEvent) => {
    setBauvorhaben(event.target.value);
  };

  const sortedBauvorhabenList = [...bauvorhabenList].sort((a, b) => a.bvh_name.localeCompare(b.bvh_name));

  // Check if the current bauvorhaben value is in the list
  // This is important for edit mode to work properly
  const bauvorhabenExists = React.useMemo(() => {
    return bauvorhabenList.some((bvh) => bvh.bvh_name === bauvorhaben);
  }, [bauvorhabenList, bauvorhaben]);

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', mt: 2, mb: 1}}>
      <FormControl required sx={{minWidth: 120, width: '100%'}}>
        <InputLabel id='bauvorhaben_id'>Bauvorhaben</InputLabel>
        <Select labelId='bauvorhaben_id' id='bauvorhaben_id-required' value={bauvorhabenExists ? bauvorhaben : ''} label='Bauvorhaben *' onChange={handleChange}>
          {sortedBauvorhabenList.map((bvh) => (
            <MenuItem key={bvh.id} value={bvh.bvh_name}>
              {bvh.bvh_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
