import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import ZeiterfassungDashboardContext from '../../../../../Contexts/ZeiterfassungDashboardContext/ZeiterfassungDashboardContext';

export default function FilterBauvorhaben() {
  const {arbeitszeiten_alle_MA, setFilter, filter, applyFilter} = useContext(
    ZeiterfassungDashboardContext
  );

  const [selectedBauvorhaben, setSelectedBauvorhaben] = useState<string>('');
  const [bauvorhabenList, setBauvorhabenList] = useState<string[]>([]);

  // Get unique BVH values from the current data
  useEffect(() => {
    if (arbeitszeiten_alle_MA && arbeitszeiten_alle_MA.arbeitszeiten) {
      const uniqueBauvorhaben = Array.from(
        new Set(
          arbeitszeiten_alle_MA.arbeitszeiten
            .map((item: any) => item.BVH)
            .filter((bvh: any) => bvh !== null && bvh !== undefined)
        )
      );

      setBauvorhabenList(uniqueBauvorhaben as string[]);
    }
  }, [arbeitszeiten_alle_MA]);

  // Update the filter when selected bauvorhaben changes
  useEffect(() => {
    setFilter({...filter, bauvorhaben: selectedBauvorhaben});
  }, [selectedBauvorhaben, setFilter]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedBauvorhaben(event.target.value);
  };

  return (
    <FormControl variant='standard' sx={{m: 1, minWidth: 250}}>
      <InputLabel id='bauvorhaben-select-label'>Bauvorhaben</InputLabel>
      <Select
        labelId='bauvorhaben-select-label'
        id='bauvorhaben-select'
        value={selectedBauvorhaben}
        MenuProps={{
          autoFocus: false,
        }}
        onChange={handleChange}
        label='Bauvorhaben'>
        <MenuItem value=''>
          <em>Alle</em>
        </MenuItem>
        {bauvorhabenList.map((bvh: string) => (
          <MenuItem key={bvh} value={bvh}>
            {bvh}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
