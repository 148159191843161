import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Paper,
  Chip,
  Stack,
  Typography,
  Divider,
  Tooltip,
} from '@mui/material';
import {Delete, AccessTime, Place, Description, Edit} from '@mui/icons-material';
import ZeiterfassungContext, {
  IArbeitszeitenDay,
} from '../../../../Contexts/ZeiterfassungContext/ZeiterfassungContext';
import dayjs from 'dayjs';

interface IZeiterfassungSelectDatumErfassteZeiten {}

export default function ZeiterfassungSelectDatumErfassteZeiten(
  props: IZeiterfassungSelectDatumErfassteZeiten
) {
  const {
    selectedDate,
    arbeitszeitenDay,
    deleteArbeitszeit,
    setCreateNeueZeitmeldung,
    createNeueZeitmeldung,
    loadArbeitszeitToEdit,
  } = React.useContext(ZeiterfassungContext);

  const [open, setOpen] = React.useState<boolean>(false);
  const [deleteID, setDeleteID] = React.useState<number>(-1);

  const handleDelete = (id: number) => {
    setDeleteID(id);
    setOpen(true);
  };

  const handleClose = (deleteEintrag: boolean) => {
    if (deleteEintrag) {
      deleteArbeitszeit(deleteID);
    }
    setDeleteID(-1);
    setOpen(false);
  };

  const handleNeueArbeitszeitmeldung = () => {
    setCreateNeueZeitmeldung(true);
  };

  const handleEditArbeitszeit = (id: number) => {
    loadArbeitszeitToEdit(id);
  };

  const formatTime = (datetest: Date) => {
    return dayjs(datetest).format('HH:mm');
  };

  const formatDuration = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    return `${hours > 9 ? '' : '0'}${hours}:${minutes > 9 ? '' : '0'}${minutes}`;
  };

  // Calculate total work time for the day
  const totalWorkTime = arbeitszeitenDay.reduce(
    (total: number, entry: IArbeitszeitenDay) =>
      total + ((entry.ende.getTime() - entry.start.getTime()) / 1000 - entry.pause),
    0
  );

  // Get current date formatted as a readable string
  const formattedDate = dayjs(selectedDate).format('dddd, DD. MMMM YYYY');

  // Get background color based on entry type
  const getEntryBackgroundColor = (taetigkeit: string | undefined) => {
    if (taetigkeit === 'Urlaub') return 'rgba(25, 118, 210, 0.08)'; // Primary blue background
    if (taetigkeit === 'Krank') return 'rgba(211, 47, 47, 0.08)'; // Error red background
    if (taetigkeit === 'Schlechtwetter') return 'rgba(237, 108, 2, 0.08)'; // Warning orange background
    if (taetigkeit === 'Arbeit') return 'rgba(76, 175, 80, 0.08)'; // Success green background
    return 'white'; // Default background
  };

  // Get chip color based on entry type
  const getEntryTypeColor = (taetigkeit: string | undefined) => {
    if (taetigkeit === 'Urlaub') return 'primary';
    if (taetigkeit === 'Krank') return 'error';
    if (taetigkeit === 'Schlechtwetter') return 'warning';
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.03)',
        }}>
        <Typography variant='h6' sx={{fontWeight: 'bold'}}>
          Erfasste Arbeitszeit:{' '}
          <span style={{color: '#1976d2'}}>{formatDuration(totalWorkTime)}</span>
        </Typography>
      </Paper>

      {arbeitszeitenDay.length === 0 ? (
        <Paper
          elevation={0}
          sx={{
            p: 3,
            mb: 2,
            textAlign: 'center',
            border: '1px dashed rgba(0, 0, 0, 0.12)',
          }}>
          <Typography color='text.secondary'>Keine Einträge für diesen Tag vorhanden</Typography>
        </Paper>
      ) : (
        <List sx={{p: 0}}>
          {arbeitszeitenDay.map((arbeitszeit, index) => {
            // Calculate the duration of this entry
            const duration =
              (arbeitszeit.ende.getTime() - arbeitszeit.start.getTime()) / 1000 - arbeitszeit.pause;

            return (
              <Paper
                key={arbeitszeit.id}
                elevation={1}
                sx={{
                  mb: 2,
                  overflow: 'hidden',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: 3,
                  },
                }}>
                <Box
                  sx={{
                    p: 0.5,
                    pl: 2,
                    backgroundColor: getEntryBackgroundColor(arbeitszeit.taetigkeit),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <Chip
                    size='small'
                    label={arbeitszeit.taetigkeit || 'Arbeit'}
                    color={getEntryTypeColor(arbeitszeit.taetigkeit)}
                  />
                  <Box sx={{display: 'flex'}}>
                    <Tooltip title='Bearbeiten'>
                      <IconButton
                        size='small'
                        onClick={() => handleEditArbeitszeit(arbeitszeit.id)}>
                        <Edit fontSize='small' />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Löschen'>
                      <IconButton
                        size='small'
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(arbeitszeit.id);
                        }}>
                        <Delete fontSize='small' />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>

                <Box
                  sx={{
                    p: 2,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleEditArbeitszeit(arbeitszeit.id)}>
                  <Stack spacing={1.5}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                      <AccessTime sx={{mr: 1, color: 'text.secondary', fontSize: 20}} />
                      <Typography variant='body1' sx={{fontWeight: 'medium'}}>
                        {formatTime(arbeitszeit.start)} - {formatTime(arbeitszeit.ende)} Uhr
                        <Typography
                          component='span'
                          variant='body2'
                          color='text.secondary'
                          sx={{ml: 1}}>
                          ({formatDuration(duration)})
                        </Typography>
                      </Typography>
                    </Box>

                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                      <Place sx={{mr: 1, color: 'text.secondary', fontSize: 20}} />
                      <Typography variant='body2' color='text.secondary'>
                        {arbeitszeit.bauvorhaben}
                      </Typography>
                    </Box>

                    {arbeitszeit.pause > 0 && (
                      <Stack direction='row' spacing={1} sx={{mt: 1}}>
                        <Chip
                          size='small'
                          label={`Pause: ${formatDuration(arbeitszeit.pause)}`}
                          variant='outlined'
                          sx={{borderRadius: 1}}
                        />
                      </Stack>
                    )}
                  </Stack>
                </Box>
              </Paper>
            );
          })}
        </List>
      )}

      {!createNeueZeitmeldung && (
        <Button
          variant='contained'
          sx={{
            mt: 1,
            py: 1,          }}
          onClick={handleNeueArbeitszeitmeldung}>
          Neue Arbeitszeitmeldung
        </Button>
      )}

      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Arbeitszeit löschen?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Soll dieser Eintrag wirklich gelöscht werden?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>Abbrechen</Button>
          <Button onClick={() => handleClose(true)} color='error' autoFocus>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
