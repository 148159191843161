/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {Arbeitszeiteintrag} from '../models/Arbeitszeiteintrag';
import type {Bauvorhaben} from '../models/Bauvorhaben';
import type {NotizArbeitstagUser} from '../models/NotizArbeitstagUser';
import type {ReturnStempeln} from '../models/ReturnStempeln';
import type {User_Zeiterfassung} from '../models/User_Zeiterfassung';

import type {CancelablePromise} from '../core/CancelablePromise';
import {OpenAPI} from '../core/OpenAPI';
import {request as __request} from '../core/request';

export class ArbeitszeitService {
  /**
   * Get a list of all Bauvorhaben
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAlleBauvorhabenArbeitzeitBauvorhabenGet(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/arbeitzeit/bauvorhaben',
    });
  }

  /**
   * Delete a Bauvorhaben
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteBauvorhaben(id: number, confirm = false): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/arbeitzeit/bauvorhaben',
      query: {
        id: id,
        confirm,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create a new Bauvorhaben
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static createBauvorhabenArbeitzeitBauvorhabenPost(requestBody: Bauvorhaben): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/arbeitzeit/bauvorhaben',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get a list of all Worktimes
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getWorktimesArbeitzeitArbeitszeiteintragGet(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/arbeitzeit/arbeitszeiteintrag',
    });
  }

  /**
   * Enter a new Worktime
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static addWorktimeArbeitzeitArbeitszeiteintragPost(requestBody: Arbeitszeiteintrag): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/arbeitzeit/arbeitszeiteintrag',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  public static updateWorktimeArbeitzeitArbeitszeiteintragPut(requestBody: Arbeitszeiteintrag): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/arbeitzeit/arbeitszeiteintrag', // "arbeitszeit" instead of "arbeitzeit"
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Worktime of User
   * @param id
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteWorktimesOfDateArbeitzeitArbeitszeiteintragDelete(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/arbeitzeit/arbeitszeiteintrag',
      query: {
        id: id,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get a list of all Worktimes of Date
   * @param date
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getWorktimesOfDateArbeitzeitArbeitszeiteintragDateGet(date: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/arbeitzeit/arbeitszeiteintrag/date',
      query: {
        date: date,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Stempel Endpoint
   * @param bvhUuid
   * @returns ReturnStempeln Successful Response
   * @throws ApiError
   */
  public static execStempelnArbeitzeitStempeleintragPost(bvhUuid: string): CancelablePromise<ReturnStempeln> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/arbeitzeit/stempeleintrag',
      query: {
        bvh_uuid: bvhUuid,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Dashboard - Avg Arbeitszeit Endpoint
   * @param timeIntervalStart
   * @param timeIntervalEnd
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAvgWorktimeArbeitzeitDashboardAvgWorktimePost(timeIntervalStart: string, timeIntervalEnd: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/arbeitzeit/dashboard/avg_worktime',
      query: {
        timeInterval_start: timeIntervalStart,
        timeInterval_end: timeIntervalEnd,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Dashboard - Arbeitszeiten Endpoint
   * @param timeIntervalStart
   * @param timeIntervalEnd
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getWorktimesOfAllUsersArbeitzeitDashboardWorktimesPost(timeIntervalStart: string, timeIntervalEnd: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/arbeitzeit/dashboard/worktimes',
      query: {
        timeInterval_start: timeIntervalStart,
        timeInterval_end: timeIntervalEnd,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Dashboard - Export Arbeitszeiten Endpoint
   * @param timeIntervalStart
   * @param timeIntervalEnd
   * @param filterDepartment
   * @param filterUser
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getWorktimesOfAllUsersAsExportArbeitzeitDashboardExportWorktimesPost(timeIntervalStart: string, timeIntervalEnd: string, filterDepartment: string, filterUser: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/arbeitzeit/dashboard/export/worktimes',
      query: {
        timeInterval_start: timeIntervalStart,
        timeInterval_end: timeIntervalEnd,
        filter_department: filterDepartment,
        filter_user: filterUser,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Dashboard - Arbeitszeiten Aggregiert Endpoint
   * @param timeIntervalStart
   * @param timeIntervalEnd
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getWorktimesOfAllUsersAggregatedArbeitzeitDashboardWorktimesAggregatedPost(timeIntervalStart: string, timeIntervalEnd: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/arbeitzeit/dashboard/worktimesAggregated',
      query: {
        timeInterval_start: timeIntervalStart,
        timeInterval_end: timeIntervalEnd,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Dashboard - Export Arbeitszeiten Aggregiert Endpoint
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static takeNoteOfWorktimesArbeitzeitDashboardTakeNotePost(requestBody: NotizArbeitstagUser): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/arbeitzeit/dashboard/take_note',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Dashboard - Update Notiz Endpoint
   * @param noteId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static updateNoteOfWorktimesArbeitzeitDashboardUpdateNotePost(noteId: number, requestBody: NotizArbeitstagUser): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/arbeitzeit/dashboard/update_note',
      query: {
        noteID: noteId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Dashboard - Delete Notiz Endpoint
   * @param noteId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteNoteOfWorktimesArbeitzeitDashboardDeleteNoteDelete(noteId: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/arbeitzeit/dashboard/delete_note',
      query: {
        note_id: noteId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Dashboard - Alle Zeiterfassungsuser
   * @returns User_Zeiterfassung Successful Response
   * @throws ApiError
   */
  public static getUsersWithZeiterfassungArbeitzeitDashboardGetUsersOfZeiterfassungGet(): CancelablePromise<Array<User_Zeiterfassung>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/arbeitzeit/dashboard/getUsers_of_Zeiterfassung',
    });
  }

  /**
   * Dashboard - Arbeitszeiten aller User
   * @param timeIntervalStart
   * @param timeIntervalEnd
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getWorktimesOfAllZeiterfassungsUsersArbeitzeitDashboardWorktimesAllUsersGet(timeIntervalStart: string, timeIntervalEnd: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/arbeitzeit/dashboard/worktimes_all_users',
      query: {
        timeInterval_start: timeIntervalStart,
        timeInterval_end: timeIntervalEnd,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Dashboard - Bearbeiter Kuerzel setzen
   * @param userId
   * @param bearbeiter
   * @param department
   * @returns any Successful Response
   * @throws ApiError
   */
  public static setBearbeiterForUserArbeitzeitDashboardWorkerSetBearbeiterPost(userId: string, bearbeiter: string, department: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/arbeitzeit/dashboard/worker/setBearbeiter',
      query: {
        user_id: userId,
        bearbeiter: bearbeiter,
        department: department,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Dashboard - Bearbeiter Kuerzel erhalten
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getBearbeiterForUsersArbeitzeitDashboardWorkerGetBearbeiterGet(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/arbeitzeit/dashboard/worker/getBearbeiter',
    });
  }
}
