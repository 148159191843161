import React from 'react';
import {Box} from '@mui/material';
import { TabPanelProps } from '../../../../services/openapi/models/Urlaubsantrag';

export function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`urlaubsantrag-tabpanel-${index}`} aria-labelledby={`urlaubsantrag-tab-${index}`} {...other}>
      {value === index && <Box sx={{p: 3}}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `urlaubsantrag-tab-${index}`,
    'aria-controls': `urlaubsantrag-tabpanel-${index}`,
  };
}
