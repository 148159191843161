import {Box, Paper, Typography, Skeleton} from '@mui/material';
import React from 'react';
import { useUrlaubsantrag } from '../../../../Contexts/Urlaubsantrag/useUrlaubsantrag';

interface UrlaubsantragOverviewProps {
  loading: boolean;
  totalVacationDays: number | null;
  remainingVacationDays: number | null;
  pendingVacationDays: number | null;
}

function UrlaubsantragOverview(props: UrlaubsantragOverviewProps) {
  const {loading, totalVacationDays, remainingVacationDays, pendingVacationDays} = props;

  // Use the hook to get all needed data
  // Calculate already used vacation days - use the data from the hook
  const usedVacationDays = totalVacationDays !== null && remainingVacationDays !== null ? totalVacationDays - remainingVacationDays : 0;

  // Calculate actual available days (remaining minus pending)
  const actualAvailableDays = remainingVacationDays !== null && pendingVacationDays !== null ? remainingVacationDays - pendingVacationDays : 0;

  // Helper function to display values with loading states
  const renderValue = (value: number | null, suffix: string = 'Tage') => {
    if (loading) return <Skeleton width={50} />;
    return `${value ?? 0} ${suffix}`;
  };

  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        minWidth: 300,
      }}>
      <Typography variant='h5' sx={{fontWeight: 'bold', mb: 1}}>
        Urlaubsübersicht
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography variant='body1'>Gesamturlaub:</Typography>
        <Typography variant='body1' fontWeight='bold'>
          {renderValue(totalVacationDays)}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography variant='body1'>Bereits genommen/genehmigt:</Typography>
        <Typography variant='body1' fontWeight='medium'>
          {renderValue(usedVacationDays)}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography variant='body1'>Beantragt:</Typography>
        <Typography variant='body1' fontWeight='medium' color={pendingVacationDays && pendingVacationDays > 0 ? 'warning.main' : 'text.primary'}>
          {renderValue(pendingVacationDays)}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1,
          borderTop: '1px dashed #e0e0e0',
        }}>
        <Typography variant='body1'>Verfügbar:</Typography>
        <Typography variant='body1' fontWeight='bold' color={actualAvailableDays > 0 ? 'success.main' : 'error.main'}>
          {renderValue(actualAvailableDays)}
        </Typography>
      </Box>
    </Paper>
  );
}

export default UrlaubsantragOverview;
