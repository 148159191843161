import {CancelablePromise} from "./openapi"

export const call_backend_api = async (promise: CancelablePromise<any>, successHandler: Function | undefined = undefined, errorHandler: Function | undefined = undefined, finallyHandler: Function | undefined = undefined) => {
    promise.then((res) => {
        if (successHandler) {
            successHandler(res)
        }
    })
        .catch((err) => {
            console.log(err)
            if (errorHandler) {
                errorHandler(err)
            }

        })
        .finally(() => {
            if (finallyHandler) {
                finallyHandler()
            }
        })
}
