import React from "react";
import { Box, Skeleton } from "@mui/material";

interface EmptyDirectoryMessageProps {
  loadingDir: boolean;
}

export const EmptyDirectoryMessage: React.FC<EmptyDirectoryMessageProps> = ({ loadingDir }) => {
  if (loadingDir) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Skeleton variant="rectangular" sx={{ flex: 1 }} height={50} />
        <Skeleton variant="rectangular" sx={{ flex: 1 }} height={50} />
      </Box>
    );
  }

  return <p>Keine Dateien vorhanden</p>;
};