/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type {CancelablePromise} from '../core/CancelablePromise';
import {OpenAPI} from '../core/OpenAPI';
import {request as __request} from '../core/request';
import {Urlaubsantrag, UrlaubsantragWithID, UrlaubsantragListeUndUser} from '../models/Urlaubsantrag';

export class UrlaubsantragService {
  /**
   * Create a new vacation request
   * @param requestBody
   * @returns UrlaubsantragWithID Successful Response
   * @throws ApiError
   */
  public static createUrlaubsantragUrlaubsantragPost(requestBody: Urlaubsantrag): CancelablePromise<UrlaubsantragWithID> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/urlaubsantrag',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get all vacation requests of the logged-in user
   * @returns UrlaubsantragWithID[] Successful Response
   * @throws ApiError
   */
  public static getMyUrlaubsantraegeUrlaubsantragGet(): CancelablePromise<Array<UrlaubsantragWithID>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/urlaubsantrag',
    });
  }

  /**
   * Delete a vacation request
   * @param id
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteUrlaubsantragUrlaubsantragDelete(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/urlaubsantrag',
      query: {
        id: id,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get all pending vacation requests
   * @returns UrlaubsantragWithID[] Successful Response
   * @throws ApiError
   */
  public static getAllPendingUrlaubsanträge(): CancelablePromise<Array<UrlaubsantragWithID>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/urlaubsantrag/dashboard/pending',
    });
  }

  /**
   * Process a vacation request (approve or reject)
   * @param id The ID of the vacation request
   * @param status Either "Genehmigt" or "Abgelehnt"
   * @returns UrlaubsantragWithID Successful Response
   * @throws ApiError
   */
  public static bearbeiteUrlaubsantragDashboardUrlaubsantragBearbeitenPost(id: number, status: string): CancelablePromise<UrlaubsantragWithID> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/urlaubsantrag/dashboard/bearbeiten',
      query: {
        id: id,
        status: status,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get an overview of all vacation requests
   * @param timeIntervalStart Start date in format YYYY-MM-DD
   * @param timeIntervalEnd End date in format YYYY-MM-DD
   * @param filterDepartment Filter by department (optional)
   * @param filterUser Filter by user ID (optional)
   * @param filterStatus Filter by status (optional)
   * @returns UrlaubsantragListeUndUser Successful Response
   * @throws ApiError
   */
  public static getUrlaubsantragOverviewDashboardUrlaubsantragOverviewPost(timeIntervalStart: string, timeIntervalEnd: string, filterDepartment?: string, filterUser?: string, filterStatus?: string): CancelablePromise<UrlaubsantragListeUndUser> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/urlaubsantrag/dashboard/overview',
      query: {
        timeInterval_start: timeIntervalStart,
        timeInterval_end: timeIntervalEnd,
        filter_department: filterDepartment,
        filter_user: filterUser,
        filter_status: filterStatus,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  public static calculateVacationDays(start: string, end: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/urlaubsantrag/calculate-days',
      body: {
        beginn: start,
        ende: end,
      },
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
