import * as React from 'react';
import {Box, TextField} from '@mui/material';
import ZeiterfassungContext from '../../../../Contexts/ZeiterfassungContext/ZeiterfassungContext';

interface IZeiterfassungTaetigkeitProps {
}

export default function ZeiterfassungTaetigkeit(props: IZeiterfassungTaetigkeitProps) {
    const {taetigkeit, setTaetigkeit, isUrlaub, isKrank, isSchlechtwetter, bauvorhaben} = React.useContext(ZeiterfassungContext);

    const isStatusSelected = isUrlaub || isKrank || isSchlechtwetter;

    // If Urlaub or Krank is selected, use that as taetigkeit but disable the field
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTaetigkeit(event.target.value);
    };

    return (
        <Box sx={{mt: 2}}>
            <TextField
                id='taetigkeit'
                label='Tätigkeit / Info'
                multiline
                rows={3}
                value={taetigkeit}
                onChange={handleChange}
                variant='outlined'
                fullWidth
                disabled={isStatusSelected}
                required={bauvorhaben.toLowerCase().includes('sonstiges')}
                sx={{
                    opacity: isStatusSelected ? 0.7 : 1,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: bauvorhaben.toLowerCase().includes('sonstiges') ? '#d32f2f' : 'default',
                        },
                        '&:hover fieldset': {
                            borderColor: bauvorhaben.toLowerCase().includes('sonstiges') ? '#d32f2f' : 'default',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: bauvorhaben.toLowerCase().includes('sonstiges') ? '#d32f2f' : 'default',
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: bauvorhaben.toLowerCase().includes('sonstiges') ? '#d32f2f' : 'default',
                    },
                    '& .Mui-focused.MuiInputLabel-root': {
                        color: bauvorhaben.toLowerCase().includes('sonstiges') ? '#d32f2f' : 'default',
                    },
                }}
            />
        </Box>
    );
}
