import dayjs, {Dayjs} from 'dayjs';
import React from 'react';
import {BauvorhabenWithID} from '../../services/openapi';

export interface IArbeitszeitenDay {
  start: Date;
  ende: Date;
  pause: number;
  bauvorhaben: string;
  taetigkeit: string | undefined;
  id: number;
}

interface ZeiterfassungContextInterface {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  arbeitszeitenDay: IArbeitszeitenDay[];
  setArbeitszeitenDay: (arbeitszeitenDay: IArbeitszeitenDay[]) => void;
  deleteArbeitszeit: (id: number) => void;
  bauvorhaben: string;
  setBauvorhaben: (bauvorhaben: string) => void;
  bauvorhabenList: BauvorhabenWithID[];
  createNeueZeitmeldung: boolean;
  setCreateNeueZeitmeldung: (createNeueZeitmeldung: boolean) => void;
  startZeit: Dayjs | null;
  setStartZeit: (startZeit: Dayjs | null) => void;
  endeZeit: Dayjs | null;
  setEndeZeit: (endeZeit: Dayjs | null) => void;
  pauseZeit: Dayjs | null;
  setPauseZeit: (pauseZeit: Dayjs | null) => void;
  taetigkeit: string;
  setTaetigkeit: (taetigkeit: string) => void;
  resetForm: () => void;
  sendArbeitszeit: () => void;
  createNewBauvorhaben: (mandnr: number, projektnr: number, bvh_name: string) => void;
  isUrlaub: boolean;
  setIsUrlaub: (isUrlaub: boolean) => void;
  isKrank: boolean;
  setIsKrank: (isKrank: boolean) => void;
  isSchlechtwetter: boolean;
  setIsSchlechtwetter: (isSchlechtwetter: boolean) => void;
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  currentEditId: number;
  setCurrentEditId: React.Dispatch<React.SetStateAction<number>>;
  loadArbeitszeitToEdit: (id: number) => void;
  updateArbeitszeit: () => void;
}

const defaultContext: ZeiterfassungContextInterface = {
  selectedDate: new Date(new Date().setHours(0, 0, 0, 0)),
  setSelectedDate: () => {},
  arbeitszeitenDay: [],
  setArbeitszeitenDay: () => {},
  deleteArbeitszeit: () => {},
  bauvorhaben: '',
  setBauvorhaben: () => {},
  bauvorhabenList: [],
  createNeueZeitmeldung: false,
  setCreateNeueZeitmeldung: () => {},
  startZeit: null,
  setStartZeit: () => {},
  endeZeit: null,
  setEndeZeit: () => {},
  pauseZeit: dayjs('1900-01-01T00:45'),
  setPauseZeit: () => {},
  resetForm: () => {},
  taetigkeit: '',
  setTaetigkeit: () => {},
  sendArbeitszeit: () => {},
  createNewBauvorhaben: () => {},
  isUrlaub: false,
  setIsUrlaub: () => {},
  isKrank: false,
  setIsKrank: () => {},
  isSchlechtwetter: false,
  setIsSchlechtwetter: () => {},
  editMode: false,
  setEditMode: () => {},
  currentEditId: -1,
  setCurrentEditId: () => {},
  loadArbeitszeitToEdit: () => {},
  updateArbeitszeit: () => {},
};

const ZeiterfassungContext = React.createContext<ZeiterfassungContextInterface>(defaultContext);

export default ZeiterfassungContext;
