import {Box, Paper, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import IndicatorWorktime from "./IndicatorWorktime";
import APIWrapperContext from "../../../../../services/APIWrapperContext";
import {ArbeitszeitService} from "../../../../../services/openapi";
import {addDaysToDate, convertDateToISODateString, getIsoWeekOfDate,} from "../timehelperFunctions";
import {IWorktime} from "./interfaces";
import LinkGeneratorForNFC from "./LinkGeneratorForNFC";
import ExportWorkingData from "./ExportWorkingData";
import WorkingDataOverview from "./WorkingDataOverview";
import ZeiterfassungDashboardContextProvider from "../../../../../Contexts/ZeiterfassungDashboardContext/ZeiterfassungDashboardContextProvider";
import BauvorhabenAnlegen from "./BauvorhabenAnlegen";
import BauvorhabenList from "./BauvorhabenList";

export default function Dashboard() {
    const {call_backend_api} = useContext(APIWrapperContext);
    const [worktimes, setWorktimes] = useState<IWorktime[]>([]);
    let today = new Date();
    let days14ago = addDaysToDate(today, -14);
    let days28ago = addDaysToDate(today, -28);
    let days7ago = addDaysToDate(today, -7);

    useEffect(() => {
        call_backend_api(
            ArbeitszeitService.getAvgWorktimeArbeitzeitDashboardAvgWorktimePost(
                convertDateToISODateString(days28ago),
                convertDateToISODateString(today)
            ),
            (res: IWorktime[]) => {
                setWorktimes(res);
            },
            undefined,
            undefined,
            false
        );
    }, []);

    return (
        // <ThemeProvider theme={theme.palette.main}>
        <>
            {/* <Paper sx={{ display: "flex", flexDirection: "column", height:"100%" }}> */}
            <Box sx={{display: "flex", flexDirection: "column", height: "100%"}}>
                <Box sx={{display: "flex", flexDirection: "row"}}>
                    <IndicatorWorktime
                        title="Diese Woche"
                        worktime={worktimes.find(
                            (el) =>
                                el.weekly === getIsoWeekOfDate(new Date()) &&
                                el.year === new Date().getFullYear()
                        )}
                        worktimeBefore={worktimes.find(
                            (el) =>
                                el.weekly === getIsoWeekOfDate(days7ago) &&
                                el.year === days7ago.getFullYear()
                        )}
                    />
                    <IndicatorWorktime
                        title="Letzte Woche"
                        worktime={worktimes.find(
                            (el) =>
                                el.weekly === getIsoWeekOfDate(days7ago) &&
                                el.year === days7ago.getFullYear()
                        )}
                        worktimeBefore={worktimes.find(
                            (el) =>
                                el.weekly === getIsoWeekOfDate(days14ago) &&
                                el.year === days14ago.getFullYear()
                        )}
                    />
                </Box>
                <Paper sx={{m: 1, p: 1}}>

                    <ZeiterfassungDashboardContextProvider>
                        <WorkingDataOverview/>
                        <ExportWorkingData/>
                    </ZeiterfassungDashboardContextProvider>
                </Paper>
                <Box sx={{flex: 1}}/>
                <BauvorhabenAnlegen/>
                <BauvorhabenList/>
                <LinkGeneratorForNFC/>
            </Box>
            {/* </Paper> */}
        </>
        // </ThemeProvider>
    );
}
