import {createTheme} from "@mui/material";


const themeOptions = {
  typography: {
    fontFamily: [
      'Dax-Pro-Regular',
      'Arial',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    type: 'light',
    primary: {
      main: '#357bc1',
      contrastText: '#ffffff',
      light: '#33ac7f',
      dark: '#006a43',
      folder_open: '#357bc138',
    },
    secondary: {
      main: '#4b4b4a',
      light: '#6f6f6e',
      dark: '#343433',
      contrastText: '#ffffff',
    },
    backgroundboe: {
      main: '#e3e3e3',
      contrastText: '#444444',
      light: '#33ac7f',
      dark: '#006a43',
    },
    background: {
      default: '#fff',
      paper: '#ffffff',
    },
  },
};
export const theme = createTheme(themeOptions);
