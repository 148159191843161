import React, {useEffect} from "react";
import {List, Typography} from "@mui/material";
import ContextViewFileList from "./Contexts/ContextViewFileList";
import {ViewFileListPath} from "./ViewFileListPath";
import {EmptyDirectoryMessage, FileListItem, NavigationBar} from "./Components";
import {DirView} from "../services/openapi";

export const FileListDetails = () => {
    const {
        dir_id_history,
        curr_dir_id,
        curr_dir_view,
        accessible_elements,
        user_specific,
        loading_dir,
        dir_navigate_back,
        dir_navigate_forward,
    } = React.useContext(ContextViewFileList);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [parentFolder, setParentFolder] = React.useState<DirView>({
        parent: "",
        hidrive_id: "",
        ending: "",
        type: "system"
    });

    useEffect(() => {
        if (dir_id_history && dir_id_history.length > 1) {
            setParentFolder({
                parent: dir_id_history[0].name,
                hidrive_id: "",
                ending: "",
                type: "system"
            })
        }

    }, [dir_id_history]);

    const handle_dir_click = (id: string) => {
        dir_navigate_forward(id);
    };

    const hasChildren = "children" in curr_dir_view &&
        Array.isArray(curr_dir_view.children) && curr_dir_view.children.length > 0;

    const sortedChildren = hasChildren && "children" in curr_dir_view && Array.isArray(curr_dir_view.children) ?
        [...curr_dir_view.children].sort((a, b) =>
            a.type === b.type
                ? (a.parent >= b.parent ? 1 : -1)
                : (a.type === 'file' ? 1 : -1)
        ) : [];

    return (
        <div style={{position: 'relative'}}>
            {user_specific && (
                <Typography variant="h3" sx={{color: 'gray'}}>
                    Meine Dokumente
                </Typography>
            )}

            <ViewFileListPath/>

            <NavigationBar
                dirIdHistory={dir_id_history}
                currDirId={curr_dir_id}
                currDirView={curr_dir_view}
                userSpecific={user_specific || false}
                dirNavigateBack={() => dir_navigate_back()}
            />

            {!hasChildren && (
                <EmptyDirectoryMessage loadingDir={loading_dir}/>
            )}

            {hasChildren && (
                <List>
{/*                    {
                        parentFolder.parent !== "" &&
                        <FileListItem
                            key={'1'}
                            element={parentFolder}
                            accessibleElements={accessible_elements}
                            userSpecific={user_specific}
                            onDirectoryClick={handle_dir_click}
                            setLoading={setLoading}
                        />
                    }*/}

                    {sortedChildren.map((element) => (
                        <FileListItem
                            key={element.hidrive_id}
                            element={element}
                            accessibleElements={accessible_elements}
                            userSpecific={user_specific}
                            onDirectoryClick={handle_dir_click}
                            setLoading={setLoading}
                        />
                    ))}
                </List>
            )}
        </div>
    );
};
