import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, TextField, Typography} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import APIWrapperContext from '../../../../../services/APIWrapperContext';
import {ArbeitszeitService, OpenAPI, UserService} from '../../../../../services/openapi';
import {Edit} from '@mui/icons-material';
import {get} from 'http';

interface IMitarbeiterEintrag {
  user: any;
  setEdit: (value: string) => void;
}

function MitarbeiterEintrag(props: IMitarbeiterEintrag) {
  const {user, setEdit} = props;
  const [hoover, setHoover] = useState(false);

  return (
    <Box
      sx={{
        minHeight: 40,
        height: 40,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: 'action.hover',
        },
      }}
      onMouseEnter={() => setHoover(true)}
      onMouseLeave={() => setHoover(false)}>
      <Box sx={{width: 40, display: 'flex', justifyContent: 'center'}}>
        {hoover && (
          <IconButton size='small' onClick={() => setEdit(user.user_id)}>
            <Edit fontSize='small' />
          </IconButton>
        )}
      </Box>

      <Box sx={{width: 200, overflow: 'hidden', textOverflow: 'ellipsis'}}>{user.fullname ? user.fullname : user.email}</Box>

      <Box sx={{width: 100, textAlign: 'center'}}>{user.zeiterfassung_bearbeiter}</Box>

      <Box sx={{width: 100, textAlign: 'center'}}>{user.zeiterfassung_abteilung}</Box>

      <Box sx={{width: 100, textAlign: 'center'}}>{user.total_vacation_days || 0}</Box>

      <Box sx={{width: 100, textAlign: 'center'}}>{user.remaining_vacation_days || 0}</Box>
    </Box>
  );
}

interface IWorkingDataSetBearbeiter {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function WorkingDataSetBearbeiter(props: IWorkingDataSetBearbeiter) {
  const {call_backend_api} = useContext(APIWrapperContext);
  const {open, setOpen} = props;
  const [mitarbeiter, setMitarbeiter] = useState<any[]>();
  const [edit_mitarbeiter, setEditMitarbeiter] = useState<string | undefined>(undefined);
  const [tmp_kuerzel, setTmpKuerzel] = useState<string | undefined>(undefined);
  const [tmp_abteilung, setTmpAbteilung] = useState<string | undefined>(undefined);
  const [tmp_total_days, setTmpTotalDays] = useState<number | undefined>(undefined);
  const [tmp_remaining_days, setTmpRemainingDays] = useState<number | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    setOpen(false);
    setEditMitarbeiter(undefined);
    setError(null);
  };

  const cancelEdit = () => {
    setEditMitarbeiter(undefined);
    setError(null);
  };

  const updateMitarbeiterList = () => {
    Promise.all([
      new Promise<any[]>((resolve) => {
        call_backend_api(
          ArbeitszeitService.getBearbeiterForUsersArbeitzeitDashboardWorkerGetBearbeiterGet(),
          (res: any) => resolve(res),
          () => resolve([]),
          undefined,
          false
        );
      }),
      new Promise<any[]>((resolve) => {
        call_backend_api(
          UserService.getAllVacationDays(),
          (res: any) => resolve(res),
          () => resolve([]),
          undefined,
          false
        );
      }),
    ]).then(([users, vacationDays]) => {
      const vacationMap = new Map<string, any>((vacationDays as any[]).map((v: any) => [v.user_id, v]));
      const mergedUsers = (users as any[]).map((user: any) => ({
        ...user,
        ...(vacationMap.get(user.user_id) || {}),
      }));
      setMitarbeiter(mergedUsers);
    });
  };

  useEffect(() => {
    if (open) {
      updateMitarbeiterList();
    }
  }, [open]);

  useEffect(() => {
    if (edit_mitarbeiter) {
      const el = mitarbeiter?.find((el) => el.user_id === edit_mitarbeiter);
      setTmpKuerzel(el?.zeiterfassung_bearbeiter);
      setTmpAbteilung(el?.zeiterfassung_abteilung);
      setTmpTotalDays(el?.total_vacation_days);
      setTmpRemainingDays(el?.remaining_vacation_days);
    }
  }, [edit_mitarbeiter]);

  const handleEdit = () => {
    if (!edit_mitarbeiter || !tmp_kuerzel || !tmp_abteilung || tmp_total_days === undefined || tmp_remaining_days === undefined) {
      setError('Bitte füllen Sie alle Felder aus');
      return;
    }

    if (tmp_total_days < tmp_remaining_days) {
      setError('Der Gesamturlaub muss größer oder gleich dem Resturlaub sein');
      return;
    }

    setError(null);

    call_backend_api(
      ArbeitszeitService.setBearbeiterForUserArbeitzeitDashboardWorkerSetBearbeiterPost(edit_mitarbeiter, tmp_kuerzel, tmp_abteilung),
      () => {
        call_backend_api(
          UserService.setVacationDaysForUser(edit_mitarbeiter, tmp_total_days, tmp_remaining_days),
          () => {
            updateMitarbeiterList();
            setEditMitarbeiter(undefined);
          },
          undefined,
          undefined,
          true
        );
      },
      undefined,
      undefined,
      true
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='lg' fullWidth>
      <DialogTitle>Bearbeiter-Kürzel und Urlaubstage definieren</DialogTitle>
      <DialogContent sx={{maxHeight: '80vh', overflow: 'hidden'}}>
        <DialogContentText sx={{mb: 2}}>Bearbeiten Sie hier die Mitarbeiterdaten.</DialogContentText>

        {error && (
          <Typography color='error' sx={{mb: 2}}>
            {error}
          </Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            fontWeight: 'bold',
            py: 1,
            borderBottom: '1px solid',
            borderColor: 'divider',
            alignItems: 'center',
          }}>
          <Box sx={{width: 40}}></Box>
          <Box sx={{width: 200}}>Mitarbeiter/-in</Box>
          <Box sx={{width: 100, textAlign: 'center'}}>Kürzel</Box>
          <Box sx={{width: 100, textAlign: 'center'}}>Abteilung</Box>
          <Box sx={{width: 100, textAlign: 'center'}}>Gesamturlaub</Box>
          <Box sx={{width: 100, textAlign: 'center'}}>Resturlaub</Box>
        </Box>

        <Box sx={{overflowY: 'auto', maxHeight: 'calc(80vh - 180px)'}}>
          {mitarbeiter?.map((el) => (
            <React.Fragment key={el.user_id}>
              {edit_mitarbeiter !== el.user_id && <MitarbeiterEintrag user={el} setEdit={setEditMitarbeiter} />}

              {edit_mitarbeiter === el.user_id && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: 'action.hover',
                    alignItems: 'center',
                    py: 1,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                  }}>
                  <Box sx={{width: 40}}></Box>
                  <Box sx={{width: 200}}>{el.fullname ? el.fullname : el.email}</Box>

                  <TextField sx={{width: 100, mx: 0.5}} size='small' value={tmp_kuerzel || ''} onChange={(e) => setTmpKuerzel(e.target.value)} inputProps={{maxLength: 3}} variant='outlined' />

                  <TextField sx={{width: 100, mx: 0.5}} size='small' value={tmp_abteilung || ''} onChange={(e) => setTmpAbteilung(e.target.value)} inputProps={{maxLength: 3}} variant='outlined' />

                  <TextField
                    sx={{width: 100, mx: 0.5}}
                    size='small'
                    type='number'
                    value={tmp_total_days ?? ''}
                    onChange={(e) => {
                      const newTotal = parseInt(e.target.value) || 0;
                      setTmpTotalDays(newTotal);
                    }}
                    error={tmp_total_days !== undefined && tmp_remaining_days !== undefined && tmp_total_days < tmp_remaining_days}
                    variant='outlined'
                  />

                  <TextField
                    sx={{width: 100, mx: 0.5}}
                    size='small'
                    type='number'
                    value={tmp_remaining_days ?? ''}
                    onChange={(e) => {
                      const newRemaining = parseInt(e.target.value) || 0;
                      setTmpRemainingDays(newRemaining);
                    }}
                    error={tmp_total_days !== undefined && tmp_remaining_days !== undefined && tmp_total_days < tmp_remaining_days}
                    variant='outlined'
                  />

                  <Box sx={{display: 'flex', gap: 1, ml: 1}}>
                    <Button variant='contained' onClick={handleEdit} size='small'>
                      Speichern
                    </Button>
                    <Button variant='outlined' onClick={cancelEdit} size='small'>
                      Abbrechen
                    </Button>
                  </Box>
                </Box>
              )}
            </React.Fragment>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
}
