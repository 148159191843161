import React, {useState} from 'react';
import {Box, Typography, Paper, Button, Tabs, Tab, Alert, Snackbar} from '@mui/material';
import {Add as AddIcon} from '@mui/icons-material';
import {a11yProps, TabPanel} from './UrlaubsantragTabPanel';
import {MeineAntraegeTab} from './meineAntraege';
import {UrlaubsantragForm} from './UrlaubsantragForm';
import {OffeneAntraegeTab} from './offeneAntraege';

import dayjs from 'dayjs';

interface VacationPeriod {
  start: string;
  end: string;
  status: string;
  id: number;
}

interface UrlaubsantragTableProps {
  loading: boolean;
  meineAntraege: any[];
  pendingAntraege: any[];
  snackbar: any;
  setSnackbar: (value: any) => void;
  formData: any;
  setFormData: (value: any) => void;
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  vacationDaysInfo: any;
  remainingVacationDays: number | null;
  pendingVacationDays: number | null;
  calculateProjectedRemainingDays: () => number;
  calculateProjectedPendingDays: () => number;
  deleteRequest: (id: number) => Promise<void>; // Changed to Promise<void>
  processRequest: (id: number, status: 'Genehmigt' | 'Abgelehnt') => Promise<void>;
  submitRequest: () => void;
  resetForm: () => void;
  loadPendingRequests: () => void;
  isApprover: boolean;
  existingVacationDates: VacationPeriod[]; // Add this prop
  checkForOverlap: (start: dayjs.Dayjs, end: dayjs.Dayjs) => boolean; // Add this prop
}

export const UrlaubsantragTable: React.FC<UrlaubsantragTableProps> = ({
  loading,
  meineAntraege,
  pendingAntraege,
  snackbar,
  setSnackbar,
  formData,
  setFormData,
  openDialog,
  setOpenDialog,
  vacationDaysInfo,
  remainingVacationDays,
  pendingVacationDays,
  calculateProjectedRemainingDays,
  calculateProjectedPendingDays,
  deleteRequest,
  processRequest,
  submitRequest,
  resetForm,
  loadPendingRequests,
  isApprover,
  existingVacationDates,
  checkForOverlap,
}) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if (newValue === 1 && isApprover) {
      loadPendingRequests();
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    resetForm();
  };

  const handleCloseSnackbar = () => {
    setSnackbar({...snackbar, open: false});
  };

  return (
    <Box sx={{width: '100%'}}>
      <Paper elevation={3} sx={{p: 2, mb: 2}}>
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2}}>
          <Typography variant='h5' sx={{fontWeight: 'bold'}} component='h1'>
            Urlaubsantrag
          </Typography>
          <Button variant='contained' startIcon={<AddIcon />} onClick={() => setOpenDialog(true)}>
            Neuer Antrag
          </Button>
        </Box>

        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label='urlaubsantrag tabs'>
            <Tab label='Meine Anträge' {...a11yProps(0)} />
            {isApprover && <Tab label='Offene Anträge' {...a11yProps(1)} />}
          </Tabs>
        </Box>

        {/* Meine Anträge Tab */}
        <TabPanel value={tabValue} index={0}>
          <MeineAntraegeTab loading={loading} antraege={meineAntraege} onDelete={deleteRequest} isApprover={isApprover} />
        </TabPanel>

        {/* Offene Anträge Tab (für Genehmiger) */}
        {isApprover && (
          <TabPanel value={tabValue} index={1}>
            <OffeneAntraegeTab loading={loading} antraege={pendingAntraege} onProcess={processRequest} />
          </TabPanel>
        )}
      </Paper>

      {/* Formular Dialog für neuen Urlaubsantrag */}
      <UrlaubsantragForm
        open={openDialog}
        onClose={handleCloseDialog}
        onSubmit={submitRequest}
        loading={loading}
        formData={formData}
        setFormData={setFormData}
        vacationDaysInfo={vacationDaysInfo}
        remainingVacationDays={remainingVacationDays}
        pendingVacationDays={pendingVacationDays}
        calculateProjectedRemainingDays={calculateProjectedRemainingDays}
        calculateProjectedPendingDays={calculateProjectedPendingDays}
        existingVacationDates={existingVacationDates}
        checkForOverlap={checkForOverlap}
      />

      {/* Snackbar für Benachrichtigungen */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{width: '100%'}}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UrlaubsantragTable;
