import React from "react";
import "./index.css";
import {GlobalDebug} from "./utils/remove-consoles";
import {Avatar, Box, Button, Container, Link, Typography, useMediaQuery,} from "@mui/material";

import LockIcon from "@mui/icons-material/Lock";
import SuperTokens, {useTranslation} from "supertokens-auth-react";
import {EmailPasswordComponentsOverrideProvider} from "supertokens-auth-react/recipe/emailpassword";

interface ILoginBottomProps {
    children?: React.ReactNode;
}

function LoginBottom(props: ILoginBottomProps) {
    const matches = useMediaQuery("(max-width:440px)");
    return (
        <>
            <Box
                sx={{
                    width: matches ? "95vw" : 420,
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            >
                {props.children}
            </Box>
        </>
    );
}

interface IOverrideSuperTokensFormProps {
    children?: React.ReactNode;
}

export const OverrideSuperTokensForm = ({
                                            children,
                                        }: IOverrideSuperTokensFormProps) => {
    // Lift the value into the parent’s state: https://reactjs.org/docs/context.html#caveats

    React.useEffect(() => {
        (process.env.NODE_ENV === "production" ||
            process.env.REACT_APP_ENV === "STAGING") &&
        GlobalDebug(false, true);
    }, []);

    return (
        <EmailPasswordComponentsOverrideProvider
            components={{
                EmailPasswordSignIn_Override: ({DefaultComponent, ...props}) => {
                    const trans = useTranslation();
                    return (
                        <div>
                            <Container
                                component="main"
                                maxWidth="md"
                                sx={{
                                    marginTop: 0,
                                }}
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        //   height: 233,
                                        width: "100%",
                                        //maxHeight: { xs: 233, md: 167 },
                                        maxWidth: "xs",
                                    }}
                                    alt="The house from the offer."
                                    src="../boe_startseite_buero_1.rgb.jpg"
                                />
                            </Container>
                            <DefaultComponent {...props} />
                            <>
                                <LoginBottom>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "76%",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                        }}
                                    >
                                        <Box onClick={() => props.forgotPasswordClick()}>
                                            <Link>{trans("forgotPassword")}</Link>
                                        </Box>
                                        <Box sx={{flex: 1}}></Box>
                                        <Box
                                            onClick={() => {
                                                if (props.signUpClicked) {
                                                    props.signUpClicked();
                                                }
                                            }}
                                        >
                                            <Link>{trans("notYetRegistered")}</Link>
                                        </Box>
                                    </Box>
                                </LoginBottom>
                            </>

                            {/* <Typography sx={{ mt: 1 }} variant="body2" color="text.secondary" align="center" {...props}> */}
                            <Typography
                                sx={{mt: 1}}
                                variant="body2"
                                color="text.secondary"
                                align="center"
                            >
                                <Button sx={{p: 0, minWidth: 30, fontSize: 18, mr: 1}} variant="contained" color={trans("language") === "de" ? "primary" : "secondary"} onClick={() => {
                                    SuperTokens.changeLanguage('de')
                                }}>{"🇩🇪"}</Button>
                                <Button sx={{p: 0, minWidth: 30, fontSize: 18, mr: 2}} variant="contained" color={trans("language") === "en" ? "primary" : "secondary"} onClick={() => {
                                    SuperTokens.changeLanguage('en')
                                }}>{"🇬🇧"}</Button>

                                {"Copyright © "}
                                <Link color="inherit" href="https://boehringer.net/">
                                    Böhringer Creativbau
                                </Link>{" "}
                                {new Date().getFullYear()}
                                {"."}
                            </Typography>
                        </div>
                    );
                },
                EmailPasswordSignInHeader_Override: ({
                                                         DefaultComponent,
                                                         ...props
                                                     }) => {
                    return (
                        <div>
                            <Box
                                sx={{width: "100%", display: "flex", flexDirection: "row"}}
                            >
                                <Box sx={{flex: 1}}></Box>
                                <Box>
                                    <Avatar sx={{bgcolor: "secondary.main"}}>
                                        <LockIcon></LockIcon>
                                    </Avatar>
                                </Box>
                                <Box sx={{flex: 1}}></Box>
                            </Box>
                            <Box
                                sx={{
                                    fontSize: 24,
                                    letterSpacing: 0.58,
                                    fontWeight: 600,
                                    m: 1,
                                }}
                            >
                                Login
                            </Box>
                            {/* <DefaultComponent {...props} /> */}
                        </div>
                    );
                },
                EmailPasswordSignInFooter_Override: ({
                                                         DefaultComponent,
                                                         ...props
                                                     }) => {
                    return (
                        <>
                            {/* <div> */}

                            {/* <Box sx={{fontSize:24, letterSpacing:0.58,fontWeight:800,m:2}}>Login</Box> */}
                            {/* {customComponent_SignInHeader(<EmailPassword></EmailPasswordSignInHeader>)} */}
                            {/* <DefaultComponent {...props} /> */}
                            {/* </div> */}
                        </>
                    );
                },
            }}
        >
            {children}
        </EmailPasswordComponentsOverrideProvider>
    );
};


