import * as React from 'react';
import {Box, Paper, Typography, IconButton, Grid, Stack} from '@mui/material';
import {ArrowBackIos, ArrowForwardIos, CalendarToday} from '@mui/icons-material';
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import ZeiterfassungContext from '../../../../Contexts/ZeiterfassungContext/ZeiterfassungContext';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

interface IZeiterfassungSelectDatum {}

export default function ZeiterfassungSelectDatum(props: IZeiterfassungSelectDatum) {
  const {selectedDate, setSelectedDate} = React.useContext(ZeiterfassungContext);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date);
    }
  };

  const goToPreviousDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    setSelectedDate(newDate);
  };

  const goToNextDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 1);
    setSelectedDate(newDate);
  };

  const goToToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    setSelectedDate(today);
  };

  // Format day name and date
  const formattedDayName = dayjs(selectedDate).locale('de').format('dddd');
  const formattedDate = dayjs(selectedDate).format('DD.MM.YYYY');
  const isToday = dayjs(selectedDate).isSame(dayjs().startOf('day'), 'day');

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        mb: 3,
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
        border: '1px solid rgba(0, 0, 0, 0.06)',
      }}>
      <Grid container alignItems='center' spacing={2}>
        <Grid item xs={12} sm={6}>
          <Stack direction='row' alignItems='center' spacing={1}>
            <CalendarToday sx={{color: 'text.secondary'}} />
            <Box>
              <Typography variant='body2' color='text.secondary' sx={{textTransform: 'capitalize'}}>
                {formattedDayName}
              </Typography>
              <Typography variant='h6' sx={{fontWeight: 'bold'}}>
                {formattedDate}
                {isToday && (
                  <Typography
                    component='span'
                    variant='caption'
                    sx={{
                      ml: 1,
                      bgcolor: 'primary.main',
                      color: 'white',
                      px: 1,
                      py: 0.5,
                      borderRadius: 1,
                      fontWeight: 'bold',
                    }}>
                    Heute
                  </Typography>
                )}
              </Typography>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Stack direction='row' spacing={1} justifyContent={{xs: 'flex-start', sm: 'flex-end'}} alignItems='center'>
            <IconButton
              onClick={goToPreviousDay}
              sx={{
                borderRadius: 1,
                bgcolor: 'background.paper',
                border: '1px solid rgba(0, 0, 0, 0.08)',
                '&:hover': {
                  bgcolor: 'rgba(25, 118, 210, 0.08)',
                },
              }}
              size='small'>
              <ArrowBackIos fontSize='small' />
            </IconButton>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
              <DatePicker
                value={dayjs(selectedDate)}
                onChange={(value) => value && handleDateChange(value.toDate())}
                slotProps={{
                  textField: {
                    size: 'small',
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 1,
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>

            <IconButton
              onClick={goToNextDay}
              sx={{
                borderRadius: 1,
                bgcolor: 'background.paper',
                border: '1px solid rgba(0, 0, 0, 0.08)',
                '&:hover': {
                  bgcolor: 'rgba(25, 118, 210, 0.08)',
                },
              }}
              size='small'>
              <ArrowForwardIos fontSize='small' />
            </IconButton>

            {!isToday && (
              <Typography
                variant='body2'
                color='primary'
                sx={{
                  cursor: 'pointer',
                  fontWeight: 'medium',
                  '&:hover': {textDecoration: 'underline'},
                }}
                onClick={goToToday}>
                Heute
              </Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}
